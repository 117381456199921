<template>
  <div class="home px-3 bg-gray">
    <b-modal
      id="modal-info"
      :title="currentInfo.title"
      hide-footer
      size="lg"
      centered
      scrollable
    >
      <div v-html="currentInfo.content"></div>
      <img
        v-if="currentInfo.id == 'shipping'"
        src="@/assets/shipping-table.jpeg"
        alt=""
        class="w-100"
      />
    </b-modal>
    <template v-if="showError">
      <div
        v-if="$route.query.error == 'cancel'"
        class="d-flex flex-column align-items-center justify-content-center text-center"
        style="min-height: 85vh"
      >
        <h1>Payment Cancelled!</h1>
        <p>You payment has been cancelled. Please try again.</p>
        <b-button variant="dark" size="lg" class="" @click="tryAgain()"
          >Continue</b-button
        >
      </div>
      <div
        v-if="$route.query.error == 'failure'"
        class="d-flex flex-column align-items-center justify-content-center text-center"
        style="min-height: 85vh"
      >
        <h1>Payment Failed!</h1>
        <p>You payment failed. Please try again.</p>
        <b-button variant="dark" size="lg" class="" @click="tryAgain()"
          >Continue</b-button
        >
      </div>
    </template>
    <div
      v-else-if="showNotFound"
      class="d-flex flex-column justify-content-center text-center"
      style="min-height: 85vh"
    >
      <h1>Page Not Found</h1>
      <p>
        The page you're looking for does not exist or have been temporarily
        unavailable
      </p>
    </div>
    <template v-else>
      <b-row align-h="center" class="d-lg-none border-bottom">
        <b-col md="12" class="centered-width">
          <b-card
            @click="visible = !visible"
            bg-variant="transparent"
            class="border-0 cursor-pointer"
            body-class="px-0"
          >
            <div
              class="d-flex align-items-center justify-content-between total-details"
            >
              <p class="mb-0">
                {{ visible ? "Hide" : "Show" }} order summary
                <!-- <b-icon
                  :icon="visible ? 'arrow-up-short' : 'arrow-down-short'"
                  class="ml-1"
                ></b-icon> -->
                <svg
                  class="ml-1"
                  width="10"
                  height="10"
                  :style="{ rotate: visible ? '180deg' : 'none' }"
                >
                  <use href="@/assets/sprite.svg#DownChevron"></use>
                </svg>
              </p>
              <h5 class="mb-0">AED {{ totalAmount }}</h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" class="bg-white order-lg-0 order-1">
          <b-row class="justify-content-lg-end justify-content-center">
            <b-col md="12" style="max-width: 41rem" class="px-4 pt-4 pb-0">
              <b-form-group
                label="Contact"
                invalid-feedback="Enter a valid email"
                label-class="adjusted-font"
                ref="email"
              >
                <b-form-input
                  type="email"
                  placeholder="Email"
                  v-model="myObj.email"
                  @focusout="tabbyValidations()"
                />
              </b-form-group>

              <b-form-checkbox v-model="myObj.offer"
                >Email me with news and offers</b-form-checkbox
              >

              <b-form-group
                label="Delivery"
                label-class="adjusted-font"
                class="my-4"
              >
                <div class="">
                  <div
                    v-for="(dd, ind) in computedOptions"
                    :key="ind"
                    class="d-flex align-items-center justify-content-between border p-3 cursor-pointer"
                    :class="{
                      'border-dark bg-gray': selected == dd.value,
                      'rounded-top': ind == 0,
                      'rounded-bottom': ind == computedOptions.length - 1,
                    }"
                    @click="
                      selected = dd.value;
                      updatePaymentRequest();
                      initTabby();
                    "
                  >
                    <span>
                      <b-icon
                        :icon="
                          selected == dd.value ? 'record-circle-fill' : 'circle'
                        "
                        class="mr-1"
                      ></b-icon>
                      {{ dd.text }}
                      <template v-if="dd.value == 'standard'">
                        {{
                          myObj.country == "United Arab Emirates"
                            ? "(Free)"
                            : `(AED ${shipFee})`
                        }}
                      </template>
                      <template v-else>
                        {{ `(AED ${shipFee})` }}
                      </template>
                    </span>
                    <!-- <b-icon :icon="dd.icon" class="ml-1"></b-icon> -->
                    <img
                      v-b-tooltip.top
                      :title="
                        dd.value == 'express'
                          ? 'Pre-Verified and Ready To Ship. Only available in UAE'
                          : ''
                      "
                      :src="dd.image"
                      alt=""
                      class="ml-1"
                      style="width: 20px"
                    />
                  </div>
                </div>
                <!-- <b-form-checkbox v-model="myObj.giftWrap" class="mt-3">
                  Gift Wrap
                </b-form-checkbox> -->
              </b-form-group>

              <b-form-group
                label="Gift Wrap"
                label-class="adjusted-font"
                class="my-4"
              >
                <div class="">
                  <div
                    v-for="(gg, ind) in giftOptions"
                    :key="ind"
                    class="d-flex align-items-center justify-content-between border p-3 cursor-pointer"
                    :class="{
                      'border-dark bg-gray': myObj.giftWrap == gg.value,
                      'rounded-top': ind == 0,
                      'rounded-bottom': ind == giftOptions.length - 1,
                    }"
                    @click="myObj.giftWrap = gg.value"
                  >
                    <div class="d-flex align-items-center">
                      <b-icon
                        :icon="
                          myObj.giftWrap == gg.value
                            ? 'record-circle-fill'
                            : 'circle'
                        "
                        class="mr-2"
                      ></b-icon>
                      <div>
                        <p class="mb-0 font-600">{{ gg.text }}</p>
                        <p class="mb-0 total-details">{{ gg.line }}</p>
                      </div>
                    </div>
                    <img
                      :src="gg.image"
                      alt=""
                      class="ml-1 rounded"
                      style="width: 55px"
                    />
                  </div>
                </div>
              </b-form-group>

              <!-- <template v-if="selected == 'standard'">
                <h6 class="mb-3">Pickup locations</h6>
                <b-alert show variant="danger">
                  <b-icon icon="info-circle" class="mr-1"></b-icon>
                  Pickup isn't available for this order. Choose another delivery
                  method.
                </b-alert>
              </template> -->

              <template>
                <b-form-group
                  label=""
                  invalid-feedback="Country is required"
                  ref="country"
                  class="position-relative"
                >
                  <label for="c-select" class="small-label text-muted"
                    >Country/Region</label
                  >
                  <b-form-select
                    id="c-select"
                    class="small-label-input"
                    v-model="myObj.country"
                    :options="countries"
                    @input="countryChange"
                  ></b-form-select>
                </b-form-group>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label=""
                      invalid-feedback="First Name is required"
                      ref="fname"
                    >
                      <b-form-input
                        placeholder="First name"
                        v-model.trim="myObj.firstName"
                        @focusout="tabbyValidations()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label=""
                      invalid-feedback="Last Name is required"
                      ref="lname"
                    >
                      <b-form-input
                        placeholder="Last name"
                        v-model.trim="myObj.lastName"
                        @focusout="tabbyValidations()"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group
                  label=""
                  invalid-feedback="Address is required"
                  ref="address"
                >
                  <b-form-input
                    placeholder="Address"
                    v-model.trim="myObj.address"
                    @focusout="tabbyValidations()"
                  />
                </b-form-group>
                <b-form-group
                  label=""
                  invalid-feedback="Apartment is required"
                  ref="apartment"
                >
                  <b-form-input
                    placeholder="Apartment, suite, etc. (optional)"
                    v-model="myObj.apartment"
                  />
                </b-form-group>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label=""
                      invalid-feedback="City is required"
                      ref="city"
                    >
                      <b-form-input
                        placeholder="City"
                        v-model.trim="myObj.city"
                        @focusout="tabbyValidations()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" v-if="myObj.country == 'United Arab Emirates'">
                    <b-form-group
                      label=""
                      invalid-feedback="Emirate is required"
                      ref="emirate"
                    >
                      <b-form-select
                        v-model="myObj.emirate"
                        :options="emirateOptions"
                        @input="tabbyValidations()"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" v-else>
                    <b-form-group
                      label=""
                      invalid-feedback="Postal Code is required"
                      ref="postal"
                    >
                      <b-form-input
                        placeholder="Postal Code"
                        v-model.trim="myObj.postalCode"
                        @focusout="tabbyValidations()"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-input-group :class="[phoneError ? 'mb-0' : 'mb-2']">
                  <b-form-input
                    type="number"
                    placeholder="Phone"
                    v-model="myObj.phone"
                    class="border-right-0"
                    ref="phone"
                    @focusout="tabbyValidations()"
                  />
                  <b-input-group-append>
                    <b-input-group-text
                      class="bg-white cursor-pointer"
                      v-b-tooltip.top
                      title="In case we need to contact you about your order."
                    >
                      <b-icon icon="question-circle"></b-icon>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <div v-if="phoneError" class="invalid-feedback mb-2">
                  Phone is required
                </div>

                <b-form-checkbox v-model="saveInfo" class="mb-3"
                  >Save this information for next time</b-form-checkbox
                >

                <!-- <h6 class="mb-3">Shipping method</h6>
                <b-alert show variant="secondary">
                  Enter your shipping address to view available shipping
                  methods.
                </b-alert> -->
              </template>

              <b-form-group
                label="Payment"
                label-class="adjusted-font"
                class="my-4"
              >
                <p class="text-muted">
                  All transactions are secure and encrypted.
                </p>
                <div>
                  <div v-for="(dd, ind) in paymentOptions" :key="ind">
                    <div
                      class="d-flex align-items-center justify-content-between border p-3 cursor-pointer"
                      :class="{
                        'border-dark bg-gray': paymentSelected == dd.value,
                        'rounded-top': ind == 0,
                        'rounded-bottom':
                          ind == paymentOptions.length - 1 &&
                          paymentSelected != dd.value,
                      }"
                      @click="
                        paymentSelected = dd.value;
                        setCollapse(ind);
                        initTabby();
                        tabbyValidations();
                      "
                    >
                      <span>
                        <b-icon
                          :icon="
                            paymentSelected == dd.value
                              ? 'record-circle-fill'
                              : 'circle'
                          "
                          class="mr-1"
                        ></b-icon>
                        {{ dd.text }}
                      </span>
                      <img :src="dd.image" alt="" style="max-width: 150px" />
                    </div>
                    <b-collapse
                      v-model="dd.state"
                      class="bg-gray border p-3"
                      :class="{
                        'rounded-bottom': ind == paymentOptions.length - 1,
                      }"
                    >
                      <b-row v-if="dd.value == 'card'">
                        <b-col md="12">
                          <stripe-element-card
                            ref="elementRef"
                            :pk="publishableKey"
                            hidePostalCode
                            @token="tokenCreated"
                            @error="handleError"
                          />
                        </b-col>
                        <!-- <b-col md="12">
                          <b-input-group class="mb-3">
                            <b-form-input
                              placeholder="Card number"
                              v-model="cardObj.cardNumber"
                              class="border-right-0"
                              ref="cno"
                            />
                            <b-input-group-append>
                              <b-input-group-text
                                class="bg-white cursor-pointer"
                              >
                                <b-icon icon="lock"></b-icon>
                              </b-input-group-text>
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-input
                            type="number"
                            placeholder="Expiration date (MM / YY)"
                            v-model="cardObj.exp"
                            ref="exp"
                            class="mb-3"
                          />
                        </b-col>
                        <b-col md="6">
                          <b-input-group class="mb-3">
                            <b-form-input
                              placeholder="Security code"
                              v-model="cardObj.cvv"
                              class="border-right-0"
                              ref="cvv"
                              type="number"
                            />
                            <b-input-group-append>
                              <b-input-group-text
                                class="bg-white cursor-pointer"
                                v-b-tooltip.top
                                title="3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front."
                              >
                                <b-icon icon="question-circle"></b-icon>
                              </b-input-group-text>
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-input
                            placeholder="Name on card"
                            v-model="cardObj.cardName"
                            ref="cname"
                            class="mb-3"
                          />
                        </b-col> -->
                      </b-row>
                      <b-row v-else-if="dd.value == 'cod'">
                        <b-col md="12" class="">
                          <p class="mb-0">
                            Cash on Delivery is available only in UAE. Please be
                            aware that we will not be able to fulfill your order
                            if you select this option from another country.
                          </p>
                          <p>
                            الدفع عند الاستلام متاح فقط في الإمارات العربية
                            المتحدة. يرجى العلم أننا لن نتمكن من تلبية طلبك إذا
                            حددت هذا الخيار من بلد آخر.
                          </p>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col md="12" class="text-center">
                          <img
                            src="@/assets/card.svg"
                            alt=""
                            class="w-50 mb-2"
                          />
                          <p class="mb-1">
                            After clicking “Pay now”, you will be redirected to
                            Pay in 4 with Tabby. No interest. No fees to
                            complete your purchase securely.
                          </p>
                          <div id="tabbyCard"></div>
                          <b-alert
                            v-if="tabbyError"
                            show
                            variant="danger"
                            class="text-left"
                          >
                            Sorry, Tabby is unable to approve this purchase.
                            Please use an alternative payment method for your
                            order.
                          </b-alert>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </div>
                </div>
              </b-form-group>

              <div class="d-lg-none">
                <b-card
                  @click="visible2 = !visible2"
                  bg-variant="transparent"
                  class="border-0 cursor-pointer"
                  body-class="px-0"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4 class="mb-0 font-600">
                      Order Summary ({{ items.length + 1 }})
                    </h4>
                    <p class="mb-0">
                      {{ visible2 ? "Hide" : "Show" }}
                      <!-- <b-icon
                        :icon="visible2 ? 'arrow-up-short' : 'arrow-down-short'"
                        class="ml-1"
                      ></b-icon> -->
                      <svg
                        class="ml-1"
                        width="10"
                        height="10"
                        :style="{ rotate: visible2 ? '180deg' : 'none' }"
                      >
                        <use href="@/assets/sprite.svg#DownChevron"></use>
                      </svg>
                    </p>
                  </div>
                </b-card>
                <b-collapse v-model="visible2">
                  <b-row class="">
                    <b-col md="12" style="" class="">
                      <div v-if="qShoe" class="freq-items mb-3">
                        <div class="position-relative">
                          <img
                            :src="qShoe.cover_image"
                            alt=""
                            width="64"
                            height="64"
                            style="object-fit: contain"
                            class="border rounded"
                          />
                          <span
                            class="item-qty bg-secondary text-white px-2 py-1"
                            pill
                          >
                            1
                          </span>
                        </div>
                        <div>
                          <p class="mb-0">{{ qShoe.name }}</p>
                          <p class="mb-0 item-size">{{ qSize }}</p>
                        </div>
                        <div>
                          <span class="item-amount">AED {{ qPrice }}</span>
                        </div>
                      </div>

                      <div
                        v-for="(item, ind) in items"
                        :key="ind"
                        class="freq-items mb-3"
                      >
                        <div class="position-relative">
                          <img
                            :src="item.imgSrc"
                            alt=""
                            width="64"
                            height="64"
                            style="object-fit: contain"
                            class="border rounded"
                          />
                          <span
                            class="item-qty bg-secondary text-white px-2 py-1 cursor-pointer"
                            pill
                            @click="removeItem(ind)"
                          >
                            X
                          </span>
                        </div>
                        <div>
                          <p class="mb-0">{{ item.Name }}</p>
                        </div>
                        <div>
                          <span class="item-amount">AED {{ item.Price }}</span>
                        </div>
                      </div>

                      <h6 class="mb-3 font-600">Frequently Bought Together</h6>
                      <div
                        v-for="(item, ind) in freqItems"
                        :key="item.id"
                        class="freq-items mb-3"
                      >
                        <div class="position-relative">
                          <img
                            :src="item.attributes.imgSrc"
                            alt=""
                            width="64"
                            height="64"
                            style="object-fit: contain"
                            class="border rounded"
                          />
                        </div>
                        <div>
                          <p class="mb-0 font-600">
                            {{ item.attributes.Name }}
                          </p>
                          <p class="mb-0 item-amount">
                            AED {{ item.attributes.Price }}
                          </p>
                        </div>
                        <div>
                          <b-button
                            variant="dark"
                            size="sm"
                            class="px-4 py-2"
                            @click="addItem(item.attributes)"
                            >Add</b-button
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-form-group
                  invalid-feedback="Enter a valid discount code or gift card"
                  ref="discount2"
                >
                  <div class="d-flex">
                    <b-form-input
                      placeholder="Discount code or gift card"
                      v-model="discountCode"
                      @keyup.enter="applyDiscount(2)"
                    />
                    <b-button
                      variant="dark"
                      size="sm"
                      class="px-4 py-2 ml-3"
                      @click="applyDiscount(2)"
                      >Apply</b-button
                    >
                  </div>
                </b-form-group>
                <div class="d-flex justify-content-between total-details mb-1">
                  <span>Subtotal</span>
                  <span class="font-600">AED {{ subTotal }}</span>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <span
                    >Shipping
                    <b-icon
                      icon="question-circle"
                      class="ml-1 cursor-pointer"
                      @click="show('shipping')"
                    ></b-icon>
                  </span>
                  <!-- <span class="text-muted">Enter shipping address</span> -->
                  <div>
                    <span
                      v-if="
                        selected == 'standard' &&
                        myObj.country == 'United Arab Emirates'
                      "
                      class="font-600 text-success"
                      >Free
                    </span>
                    <span
                      class="font-600"
                      :class="{
                        'cross-out':
                          selected == 'standard' &&
                          myObj.country == 'United Arab Emirates',
                      }"
                      >AED {{ shipFee }}</span
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <span>Processing Fee</span>
                  <span class="font-600">AED {{ processFee }}</span>
                </div>
                <div
                  v-if="discount > 0"
                  class="d-flex justify-content-between total-details mb-2"
                >
                  <span>Discount</span>
                  <span class="font-600">AED {{ discount }}</span>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <h5 class="mb-0 font-600">Total</h5>
                  <h5 class="mb-0 font-600">AED {{ totalAmount }}</h5>
                </div>
              </div>

              <b-button
                variant="dark"
                size="lg"
                block
                class="py-3 mt-4 mb-3"
                @click="formSubmit"
                :disabled="processing"
              >
                Pay Now
              </b-button>
              <div id="payment-request-button"></div>
              <div v-if="errorMessage" class="text-danger mt-3">
                {{ errorMessage }}
              </div>
            </b-col>
            <b-col md="12" class="p-0">
              <hr class="mt-5" />
            </b-col>
            <b-col md="12" style="max-width: 41rem" class="px-4 pb-4">
              <ul class="policies">
                <li @click="show('refund')">Refund Policy</li>
                <li @click="show('shipping')">Shipping Policy</li>
                <li @click="show('privacy')">Privacy Policy</li>
                <li @click="show('terms')">Terms of service</li>
                <li @click="show('contact')">Contact information</li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" class="">
          <b-collapse v-model="visible">
            <b-row class="justify-content-lg-start justify-content-center">
              <b-col md="12" style="" class="p-4 centered-width">
                <div v-if="qShoe" class="freq-items mb-3">
                  <div class="position-relative">
                    <img
                      :src="qShoe.cover_image"
                      alt=""
                      width="64"
                      height="64"
                      style="object-fit: contain"
                      class="border rounded"
                    />
                    <span
                      class="item-qty bg-secondary text-white px-2 py-1"
                      pill
                    >
                      1
                    </span>
                  </div>
                  <div>
                    <p class="mb-0">{{ qShoe.name }}</p>
                    <p class="mb-0 item-size">{{ qSize }}</p>
                  </div>
                  <div>
                    <span class="item-amount">AED {{ qPrice }}</span>
                  </div>
                </div>

                <div
                  v-for="(item, ind) in items"
                  :key="ind"
                  class="freq-items mb-3"
                >
                  <div class="position-relative">
                    <img
                      :src="item.imgSrc"
                      alt=""
                      width="64"
                      height="64"
                      style="object-fit: contain"
                      class="border rounded"
                    />
                    <span
                      class="item-qty bg-secondary text-white px-2 py-1 cursor-pointer"
                      pill
                      @click="removeItem(ind)"
                    >
                      X
                    </span>
                  </div>
                  <div>
                    <p class="mb-0">{{ item.Name }}</p>
                  </div>
                  <div>
                    <span class="item-amount">AED {{ item.Price }}</span>
                  </div>
                </div>

                <h6 class="mb-3 font-600">Frequently Bought Together</h6>
                <div
                  v-for="(item, ind) in freqItems"
                  :key="item.id"
                  class="freq-items mb-3"
                >
                  <div class="position-relative">
                    <img
                      :src="item.attributes.imgSrc"
                      alt=""
                      width="64"
                      height="64"
                      style="object-fit: contain"
                      class="border rounded"
                    />
                  </div>
                  <div>
                    <p class="mb-0 font-600">
                      {{ item.attributes.Name }}
                    </p>
                    <p class="mb-0 item-amount">
                      AED {{ item.attributes.Price }}
                    </p>
                  </div>
                  <div>
                    <b-button
                      variant="dark"
                      size="sm"
                      class="px-4 py-2"
                      @click="addItem(item.attributes)"
                      >Add</b-button
                    >
                  </div>
                </div>

                <b-form-group
                  invalid-feedback="Enter a valid discount code or gift card"
                  ref="discount1"
                >
                  <div class="d-flex">
                    <b-form-input
                      placeholder="Discount code or gift card"
                      v-model="discountCode"
                      @keyup.enter="applyDiscount(1)"
                    />
                    <b-button
                      variant="dark"
                      size="sm"
                      class="px-4 py-2 ml-3"
                      @click="applyDiscount(1)"
                      >Apply</b-button
                    >
                  </div>
                </b-form-group>

                <div class="d-flex justify-content-between total-details mb-1">
                  <span>Subtotal</span>
                  <span class="font-600">AED {{ subTotal }}</span>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <span
                    >Shipping
                    <b-icon
                      icon="question-circle"
                      class="ml-1 cursor-pointer"
                      @click="show('shipping')"
                    ></b-icon>
                  </span>
                  <!-- <span class="text-muted">Enter shipping address</span> -->
                  <div>
                    <span
                      v-if="
                        selected == 'standard' &&
                        myObj.country == 'United Arab Emirates'
                      "
                      class="font-600 text-success"
                      >Free
                    </span>
                    <span
                      class="font-600"
                      :class="{
                        'cross-out':
                          selected == 'standard' &&
                          myObj.country == 'United Arab Emirates',
                      }"
                      >AED {{ shipFee }}</span
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <span>Processing Fee</span>
                  <span class="font-600">AED {{ processFee }}</span>
                </div>
                <div
                  v-if="discount > 0"
                  class="d-flex justify-content-between total-details mb-2"
                >
                  <span>Discount</span>
                  <span class="font-600">AED {{ discount }}</span>
                </div>
                <div class="d-flex justify-content-between total-details mb-1">
                  <h5 class="mb-0 font-600">Total</h5>
                  <h5 class="mb-0 font-600">AED {{ totalAmount }}</h5>
                </div>
                <!-- <p class="mb-0">Including AED {{ taxAmount }} in taxes</p> -->
                <div class="d-flex total-details mt-4">
                  <!-- <img
                    src="@/assets/thumb.png"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  /> -->
                  <i class="thumb-icon mr-2"></i>
                  <div>
                    <h6 class="mb-0" style="color: #b87253">
                      Buy with confidence
                    </h6>
                    <p class="mb-0">
                      Free easy returns up to 30 days.
                      <span
                        class="text-muted cursor-pointer"
                        style="text-decoration: underline"
                        @click="show('refund')"
                        >Learn More</span
                      >
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
// @ is an alias to /src
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BCollapse,
  BCard,
  BAlert,
} from "bootstrap-vue";
export default {
  name: "HomeView",
  components: {
    StripeElementCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BCollapse,
    BCard,
    BAlert,
  },
  data() {
    return {
      publishableKey:
        "pk_live_51IiIJ9BPtBbBG1D4RECcgbhgde32T8O7otN8o4yUWnfEKlowDBlHkGg1KF8YWaAe7bfW7zAM3nrE2S9LHFGnJHbl00rZXzZiqQ",
      // publishableKey:
      //   "pk_test_51IiIJ9BPtBbBG1D4Lw59W0SkR7Zn4rnXVN9l5De0uR6wYinpi0lKsikv4M6v3sM4ip1hUOEaPbepjDTU8mGMonhj00edd6puGr",
      cardToken: null,
      visible: true,
      visible2: false,
      selected: "express",
      options: [
        {
          text: "Express Delivery: 1-3 days",
          value: "express",
          icon: "truck",
          image: require("@/assets/rocket.png"),
        },
        {
          text: "Standard Delivery: 4-7 days",
          value: "standard",
          icon: "shop",
          image: require("@/assets/truck.png"),
        },
      ],
      paymentSelected: "card",
      paymentOptions: [
        {
          text: "Credit card",
          value: "card",
          image: require("@/assets/payments.png"),
          state: true,
        },
        // {
        //   text: "Pay in 4 with Tabby. No interest. No fees",
        //   value: "tabby",
        //   image: require("@/assets/tabby.svg"),
        //   state: false,
        // },
        {
          text: "Cash on Delivery (COD)",
          value: "cod",
          image: "",
          state: false,
        },
      ],
      giftOptions: [
        {
          text: "Eco-Friendly Packaging (Free)",
          value: "Eco-Friendly Packaging",
          line: "Go green with our double boxed secure packaging.",
          image: require("@/assets/gift2.png"),
        },
        {
          text: "Premium Packaging (Free)",
          value: "Premium Packaging",
          line: "Luxury gift box. Perfect for those special occasions ",
          image: require("@/assets/gift1.png"),
        },
      ],
      emirateOptions: [
        { text: "Select emirate", value: "", disabled: true },
        { text: "Abu Dhabi", value: "Abu Dhabi" },
        { text: "Ajman", value: "Ajman" },
        { text: "Dubai", value: "Dubai" },
        { text: "Ras al-Khaimah", value: "Ras al-Khaimah" },
        { text: "Sharjah", value: "Sharjah" },
        { text: "Umm al-Quwain", value: "Umm al-Quwain" },
      ],
      countries: [
        { text: "Select country", value: "", disabled: true },
        {
          text: "United Arab Emirates",
          value: "United Arab Emirates",
          code: "AE",
        },
        {
          text: "Afghanistan",
          value: "Afghanistan",
          code: "AF",
        },
        {
          text: "Åland Islands",
          value: "Åland Islands",
          code: "AX",
        },
        {
          text: "Albania",
          value: "Albania",
          code: "AL",
        },
        {
          text: "Algeria",
          value: "Algeria",
          code: "DZ",
        },
        {
          text: "Andorra",
          value: "Andorra",
          code: "AD",
        },
        {
          text: "Angola",
          value: "Angola",
          code: "AO",
        },
        {
          text: "Anguilla",
          value: "Anguilla",
          code: "AI",
        },
        {
          text: "Antarctica",
          value: "Antarctica",
          code: "AQ",
        },
        {
          text: "Antigua and Barbuda",
          value: "Antigua and Barbuda",
          code: "AG",
        },
        {
          text: "Argentina",
          value: "Argentina",
          code: "AR",
        },
        {
          text: "Armenia",
          value: "Armenia",
          code: "AM",
        },
        {
          text: "Aruba",
          value: "Aruba",
          code: "AW",
        },
        {
          text: "Ascension Island",
          value: "Ascension Island",
          code: "AC",
        },
        {
          text: "Australia",
          value: "Australia",
          code: "AU",
        },
        {
          text: "Austria",
          value: "Austria",
          code: "AT",
        },
        {
          text: "Azerbaijan",
          value: "Azerbaijan",
          code: "AZ",
        },
        {
          text: "Bahamas",
          value: "Bahamas",
          code: "BS",
        },
        {
          text: "Bahrain",
          value: "Bahrain",
          code: "BH",
        },
        {
          text: "Bangladesh",
          value: "Bangladesh",
          code: "BD",
        },
        {
          text: "Barbados",
          value: "Barbados",
          code: "BB",
        },
        {
          text: "Belarus",
          value: "Belarus",
          code: "BY",
        },
        {
          text: "Belgium",
          value: "Belgium",
          code: "BE",
        },
        {
          text: "Belize",
          value: "Belize",
          code: "BZ",
        },
        {
          text: "Benin",
          value: "Benin",
          code: "BJ",
        },
        {
          text: "Bermuda",
          value: "Bermuda",
          code: "BM",
        },
        {
          text: "Bhutan",
          value: "Bhutan",
          code: "BT",
        },
        {
          text: "Bolivia",
          value: "Bolivia",
          code: "BO",
        },
        {
          text: "Bosnia and Herzegovina",
          value: "Bosnia and Herzegovina",
          code: "BA",
        },
        {
          text: "Botswana",
          value: "Botswana",
          code: "BW",
        },
        {
          text: "Bouvet Island",
          value: "Bouvet Island",
          code: "BV",
        },
        {
          text: "Brazil",
          value: "Brazil",
          code: "BR",
        },
        {
          text: "British Indian Ocean Territory",
          value: "British Indian Ocean Territory",
          code: "IO",
        },
        {
          text: "British Virgin Islands",
          value: "British Virgin Islands",
          code: "VG",
        },
        {
          text: "Brunei",
          value: "Brunei",
          code: "BN",
        },
        {
          text: "Bulgaria",
          value: "Bulgaria",
          code: "BG",
        },
        {
          text: "Burkina Faso",
          value: "Burkina Faso",
          code: "BF",
        },
        {
          text: "Burundi",
          value: "Burundi",
          code: "BI",
        },
        {
          text: "Cambodia",
          value: "Cambodia",
          code: "KH",
        },
        {
          text: "Cameroon",
          value: "Cameroon",
          code: "CM",
        },
        {
          text: "Canada",
          value: "Canada",
          code: "CA",
        },
        {
          text: "Cape Verde",
          value: "Cape Verde",
          code: "CV",
        },
        {
          text: "Caribbean Netherlands",
          value: "Caribbean Netherlands",
          code: "BQ",
        },
        {
          text: "Cayman Islands",
          value: "Cayman Islands",
          code: "KY",
        },
        {
          text: "Central African Republic",
          value: "Central African Republic",
          code: "CF",
        },
        {
          text: "Chad",
          value: "Chad",
          code: "TD",
        },
        {
          text: "Chile",
          value: "Chile",
          code: "CL",
        },
        {
          text: "China",
          value: "China",
          code: "CN",
        },
        {
          text: "Colombia",
          value: "Colombia",
          code: "CO",
        },
        {
          text: "Comoros",
          value: "Comoros",
          code: "KM",
        },
        {
          text: "Congo - Brazzaville",
          value: "Congo - Brazzaville",
          code: "CG",
        },
        {
          text: "Congo - Kinshasa",
          value: "Congo - Kinshasa",
          code: "CD",
        },
        {
          text: "Cook Islands",
          value: "Cook Islands",
          code: "CK",
        },
        {
          text: "Costa Rica",
          value: "Costa Rica",
          code: "CR",
        },
        {
          text: "Côte d’Ivoire",
          value: "Côte d’Ivoire",
          code: "CI",
        },
        {
          text: "Croatia",
          value: "Croatia",
          code: "HR",
        },
        {
          text: "Curaçao",
          value: "Curaçao",
          code: "CW",
        },
        {
          text: "Cyprus",
          value: "Cyprus",
          code: "CY",
        },
        {
          text: "Czechia",
          value: "Czechia",
          code: "CZ",
        },
        {
          text: "Denmark",
          value: "Denmark",
          code: "DK",
        },
        {
          text: "Djibouti",
          value: "Djibouti",
          code: "DJ",
        },
        {
          text: "Dominica",
          value: "Dominica",
          code: "DM",
        },
        {
          text: "Dominican Republic",
          value: "Dominican Republic",
          code: "DO",
        },
        {
          text: "Ecuador",
          value: "Ecuador",
          code: "EC",
        },
        {
          text: "Egypt",
          value: "Egypt",
          code: "EG",
        },
        {
          text: "El Salvador",
          value: "El Salvador",
          code: "SV",
        },
        {
          text: "Equatorial Guinea",
          value: "Equatorial Guinea",
          code: "GQ",
        },
        {
          text: "Eritrea",
          value: "Eritrea",
          code: "ER",
        },
        {
          text: "Estonia",
          value: "Estonia",
          code: "EE",
        },
        {
          text: "Eswatini",
          value: "Eswatini",
          code: "SZ",
        },
        {
          text: "Ethiopia",
          value: "Ethiopia",
          code: "ET",
        },
        {
          text: "Falkland Islands",
          value: "Falkland Islands",
          code: "FK",
        },
        {
          text: "Faroe Islands",
          value: "Faroe Islands",
          code: "FO",
        },
        {
          text: "Fiji",
          value: "Fiji",
          code: "FJ",
        },
        {
          text: "Finland",
          value: "Finland",
          code: "FI",
        },
        {
          text: "France",
          value: "France",
          code: "FR",
        },
        {
          text: "French Guiana",
          value: "French Guiana",
          code: "GF",
        },
        {
          text: "French Polynesia",
          value: "French Polynesia",
          code: "PF",
        },
        {
          text: "French Southern Territories",
          value: "French Southern Territories",
          code: "TF",
        },
        {
          text: "Gabon",
          value: "Gabon",
          code: "GA",
        },
        {
          text: "Gambia",
          value: "Gambia",
          code: "GM",
        },
        {
          text: "Georgia",
          value: "Georgia",
          code: "GE",
        },
        {
          text: "Germany",
          value: "Germany",
          code: "DE",
        },
        {
          text: "Ghana",
          value: "Ghana",
          code: "GH",
        },
        {
          text: "Gibraltar",
          value: "Gibraltar",
          code: "GI",
        },
        {
          text: "Greece",
          value: "Greece",
          code: "GR",
        },
        {
          text: "Greenland",
          value: "Greenland",
          code: "GL",
        },
        {
          text: "Grenada",
          value: "Grenada",
          code: "GD",
        },
        {
          text: "Guadeloupe",
          value: "Guadeloupe",
          code: "GP",
        },
        {
          text: "Guam",
          value: "Guam",
          code: "GU",
        },
        {
          text: "Guatemala",
          value: "Guatemala",
          code: "GT",
        },
        {
          text: "Guernsey",
          value: "Guernsey",
          code: "GG",
        },
        {
          text: "Guinea",
          value: "Guinea",
          code: "GN",
        },
        {
          text: "Guinea-Bissau",
          value: "Guinea-Bissau",
          code: "GW",
        },
        {
          text: "Guyana",
          value: "Guyana",
          code: "GY",
        },
        {
          text: "Haiti",
          value: "Haiti",
          code: "HT",
        },
        {
          text: "Honduras",
          value: "Honduras",
          code: "HN",
        },
        {
          text: "Hong Kong",
          value: "Hong Kong",
          code: "HK",
        },
        {
          text: "Hungary",
          value: "Hungary",
          code: "HU",
        },
        {
          text: "Iceland",
          value: "Iceland",
          code: "IS",
        },
        {
          text: "India",
          value: "India",
          code: "IN",
        },
        {
          text: "Indonesia",
          value: "Indonesia",
          code: "ID",
        },
        {
          text: "Iraq",
          value: "Iraq",
          code: "IQ",
        },
        {
          text: "Ireland",
          value: "Ireland",
          code: "IE",
        },
        {
          text: "Isle of Man",
          value: "Isle of Man",
          code: "IM",
        },
        {
          text: "Israel",
          value: "Israel",
          code: "IL",
        },
        {
          text: "Italy",
          value: "Italy",
          code: "IT",
        },
        {
          text: "Jamaica",
          value: "Jamaica",
          code: "JM",
        },
        {
          text: "Japan",
          value: "Japan",
          code: "JP",
        },
        {
          text: "Jersey",
          value: "Jersey",
          code: "JE",
        },
        {
          text: "Jordan",
          value: "Jordan",
          code: "JO",
        },
        {
          text: "Kazakhstan",
          value: "Kazakhstan",
          code: "KZ",
        },
        {
          text: "Kenya",
          value: "Kenya",
          code: "KE",
        },
        {
          text: "Kiribati",
          value: "Kiribati",
          code: "KI",
        },
        {
          text: "Kosovo",
          value: "Kosovo",
          code: "XK",
        },
        {
          text: "Kuwait",
          value: "Kuwait",
          code: "KW",
        },
        {
          text: "Kyrgyzstan",
          value: "Kyrgyzstan",
          code: "KG",
        },
        {
          text: "Laos",
          value: "Laos",
          code: "LA",
        },
        {
          text: "Latvia",
          value: "Latvia",
          code: "LV",
        },
        {
          text: "Lebanon",
          value: "Lebanon",
          code: "LB",
        },
        {
          text: "Lesotho",
          value: "Lesotho",
          code: "LS",
        },
        {
          text: "Liberia",
          value: "Liberia",
          code: "LR",
        },
        {
          text: "Libya",
          value: "Libya",
          code: "LY",
        },
        {
          text: "Liechtenstein",
          value: "Liechtenstein",
          code: "LI",
        },
        {
          text: "Lithuania",
          value: "Lithuania",
          code: "LT",
        },
        {
          text: "Luxembourg",
          value: "Luxembourg",
          code: "LU",
        },
        {
          text: "Macao SAR China",
          value: "Macao SAR China",
          code: "MO",
        },
        {
          text: "Madagascar",
          value: "Madagascar",
          code: "MG",
        },
        {
          text: "Malawi",
          value: "Malawi",
          code: "MW",
        },
        {
          text: "Malaysia",
          value: "Malaysia",
          code: "MY",
        },
        {
          text: "Maldives",
          value: "Maldives",
          code: "MV",
        },
        {
          text: "Mali",
          value: "Mali",
          code: "ML",
        },
        {
          text: "Malta",
          value: "Malta",
          code: "MT",
        },
        {
          text: "Martinique",
          value: "Martinique",
          code: "MQ",
        },
        {
          text: "Mauritania",
          value: "Mauritania",
          code: "MR",
        },
        {
          text: "Mauritius",
          value: "Mauritius",
          code: "MU",
        },
        {
          text: "Mayotte",
          value: "Mayotte",
          code: "YT",
        },
        {
          text: "Mexico",
          value: "Mexico",
          code: "MX",
        },
        {
          text: "Moldova",
          value: "Moldova",
          code: "MD",
        },
        {
          text: "Monaco",
          value: "Monaco",
          code: "MC",
        },
        {
          text: "Mongolia",
          value: "Mongolia",
          code: "MN",
        },
        {
          text: "Montenegro",
          value: "Montenegro",
          code: "ME",
        },
        {
          text: "Montserrat",
          value: "Montserrat",
          code: "MS",
        },
        {
          text: "Morocco",
          value: "Morocco",
          code: "MA",
        },
        {
          text: "Mozambique",
          value: "Mozambique",
          code: "MZ",
        },
        {
          text: "Myanmar (Burma)",
          value: "Myanmar (Burma)",
          code: "MM",
        },
        {
          text: "Namibia",
          value: "Namibia",
          code: "NA",
        },
        {
          text: "Nauru",
          value: "Nauru",
          code: "NR",
        },
        {
          text: "Nepal",
          value: "Nepal",
          code: "NP",
        },
        {
          text: "Netherlands",
          value: "Netherlands",
          code: "NL",
        },
        {
          text: "New Caledonia",
          value: "New Caledonia",
          code: "NC",
        },
        {
          text: "New Zealand",
          value: "New Zealand",
          code: "NZ",
        },
        {
          text: "Nicaragua",
          value: "Nicaragua",
          code: "NI",
        },
        {
          text: "Niger",
          value: "Niger",
          code: "NE",
        },
        {
          text: "Nigeria",
          value: "Nigeria",
          code: "NG",
        },
        {
          text: "Niue",
          value: "Niue",
          code: "NU",
        },
        {
          text: "North Macedonia",
          value: "North Macedonia",
          code: "MK",
        },
        {
          text: "Norway",
          value: "Norway",
          code: "NO",
        },
        {
          text: "Oman",
          value: "Oman",
          code: "OM",
        },
        {
          text: "Pakistan",
          value: "Pakistan",
          code: "PK",
        },
        {
          text: "Palestinian Territories",
          value: "Palestinian Territories",
          code: "PS",
        },
        {
          text: "Panama",
          value: "Panama",
          code: "PA",
        },
        {
          text: "Papua New Guinea",
          value: "Papua New Guinea",
          code: "PG",
        },
        {
          text: "Paraguay",
          value: "Paraguay",
          code: "PY",
        },
        {
          text: "Peru",
          value: "Peru",
          code: "PE",
        },
        {
          text: "Philippines",
          value: "Philippines",
          code: "PH",
        },
        {
          text: "Pitcairn Islands",
          value: "Pitcairn Islands",
          code: "PN",
        },
        {
          text: "Poland",
          value: "Poland",
          code: "PL",
        },
        {
          text: "Portugal",
          value: "Portugal",
          code: "PT",
        },
        {
          text: "Puerto Rico",
          value: "Puerto Rico",
          code: "PR",
        },
        {
          text: "Qatar",
          value: "Qatar",
          code: "QA",
        },
        {
          text: "Réunion",
          value: "Réunion",
          code: "RE",
        },
        {
          text: "Romania",
          value: "Romania",
          code: "RO",
        },
        {
          text: "Russia",
          value: "Russia",
          code: "RU",
        },
        {
          text: "Rwanda",
          value: "Rwanda",
          code: "RW",
        },
        {
          text: "Samoa",
          value: "Samoa",
          code: "WS",
        },
        {
          text: "San Marino",
          value: "San Marino",
          code: "SM",
        },
        {
          text: "São Tomé and Príncipe",
          value: "São Tomé and Príncipe",
          code: "ST",
        },
        {
          text: "Saudi Arabia",
          value: "Saudi Arabia",
          code: "SA",
        },
        {
          text: "Senegal",
          value: "Senegal",
          code: "SN",
        },
        {
          text: "Serbia",
          value: "Serbia",
          code: "RS",
        },
        {
          text: "Seychelles",
          value: "Seychelles",
          code: "SC",
        },
        {
          text: "Sierra Leone",
          value: "Sierra Leone",
          code: "SL",
        },
        {
          text: "Singapore",
          value: "Singapore",
          code: "SG",
        },
        {
          text: "Sint Maarten",
          value: "Sint Maarten",
          code: "SX",
        },
        {
          text: "Slovakia",
          value: "Slovakia",
          code: "SK",
        },
        {
          text: "Slovenia",
          value: "Slovenia",
          code: "SI",
        },
        {
          text: "Solomon Islands",
          value: "Solomon Islands",
          code: "SB",
        },
        {
          text: "Somalia",
          value: "Somalia",
          code: "SO",
        },
        {
          text: "South Africa",
          value: "South Africa",
          code: "ZA",
        },
        {
          text: "South Georgia and South Sandwich Islands",
          value: "South Georgia and South Sandwich Islands",
          code: "GS",
        },
        {
          text: "South Korea",
          value: "South Korea",
          code: "KR",
        },
        {
          text: "South Sudan",
          value: "South Sudan",
          code: "SS",
        },
        {
          text: "Spain",
          value: "Spain",
          code: "ES",
        },
        {
          text: "Sri Lanka",
          value: "Sri Lanka",
          code: "LK",
        },
        {
          text: "St. Barthélemy",
          value: "St. Barthélemy",
          code: "BL",
        },
        {
          text: "St. Helena",
          value: "St. Helena",
          code: "SH",
        },
        {
          text: "St. Kitts and Nevis",
          value: "St. Kitts and Nevis",
          code: "KN",
        },
        {
          text: "St. Lucia",
          value: "St. Lucia",
          code: "LC",
        },
        {
          text: "St. Martin",
          value: "St. Martin",
          code: "MF",
        },
        {
          text: "St. Pierre and Miquelon",
          value: "St. Pierre and Miquelon",
          code: "PM",
        },
        {
          text: "St. Vincent and Grenadines",
          value: "St. Vincent and Grenadines",
          code: "VC",
        },
        {
          text: "Sudan",
          value: "Sudan",
          code: "SD",
        },
        {
          text: "Suriname",
          value: "Suriname",
          code: "SR",
        },
        {
          text: "Svalbard and Jan Mayen",
          value: "Svalbard and Jan Mayen",
          code: "SJ",
        },
        {
          text: "Sweden",
          value: "Sweden",
          code: "SE",
        },
        {
          text: "Switzerland",
          value: "Switzerland",
          code: "CH",
        },
        {
          text: "Taiwan",
          value: "Taiwan",
          code: "TW",
        },
        {
          text: "Tajikistan",
          value: "Tajikistan",
          code: "TJ",
        },
        {
          text: "Tanzania",
          value: "Tanzania",
          code: "TZ",
        },
        {
          text: "Thailand",
          value: "Thailand",
          code: "TH",
        },
        {
          text: "Timor-Leste",
          value: "Timor-Leste",
          code: "TL",
        },
        {
          text: "Togo",
          value: "Togo",
          code: "TG",
        },
        {
          text: "Tokelau",
          value: "Tokelau",
          code: "TK",
        },
        {
          text: "Tonga",
          value: "Tonga",
          code: "TO",
        },
        {
          text: "Trinidad and Tobago",
          value: "Trinidad and Tobago",
          code: "TT",
        },
        {
          text: "Tristan da Cunha",
          value: "Tristan da Cunha",
          code: "TA",
        },
        {
          text: "Tunisia",
          value: "Tunisia",
          code: "TN",
        },
        {
          text: "Türkiye",
          value: "Türkiye",
          code: "TR",
        },
        {
          text: "Turkmenistan",
          value: "Turkmenistan",
          code: "TM",
        },
        {
          text: "Turks and Caicos Islands",
          value: "Turks and Caicos Islands",
          code: "TC",
        },
        {
          text: "Tuvalu",
          value: "Tuvalu",
          code: "TV",
        },
        {
          text: "Uganda",
          value: "Uganda",
          code: "UG",
        },
        {
          text: "Ukraine",
          value: "Ukraine",
          code: "UA",
        },
        {
          text: "United Arab Emirates",
          value: "United Arab Emirates",
          code: "AE",
        },
        {
          text: "United Kingdom",
          value: "United Kingdom",
          code: "GB",
        },
        {
          text: "United States",
          value: "United States",
          code: "US",
        },
        {
          text: "Uruguay",
          value: "Uruguay",
          code: "UY",
        },
        {
          text: "Uzbekistan",
          value: "Uzbekistan",
          code: "UZ",
        },
        {
          text: "Vanuatu",
          value: "Vanuatu",
          code: "VU",
        },
        {
          text: "Vatican City",
          value: "Vatican City",
          code: "VA",
        },
        {
          text: "Venezuela",
          value: "Venezuela",
          code: "VE",
        },
        {
          text: "Vietnam",
          value: "Vietnam",
          code: "VN",
        },
        {
          text: "Wallis and Futuna",
          value: "Wallis and Futuna",
          code: "WF",
        },
        {
          text: "Western Sahara",
          value: "Western Sahara",
          code: "EH",
        },
        {
          text: "Yemen",
          value: "Yemen",
          code: "YE",
        },
        {
          text: "Zambia",
          value: "Zambia",
          code: "ZM",
        },
        {
          text: "Zimbabwe",
          value: "Zimbabwe",
          code: "ZW",
        },
      ],
      taxAmount: 250.76,
      myObj: {
        email: "",
        country: "United Arab Emirates",
        firstName: "",
        lastName: "",
        address: "",
        apartment: "",
        postalCode: "",
        city: "",
        phone: "",
        emirate: "",
        offer: false,
        giftWrap: "Eco-Friendly Packaging",
      },
      cardObj: {
        cardNumber: "",
        exp: "",
        cvv: "",
        cardName: "",
      },
      discountCode: "",
      discount: 0,
      items: [
        // {
        //   image: require("@/assets/shoe.png"),
        //   name: "Nike SB Dunk Low Futura Laboratories Bleached Aqua",
        //   amount: 2600.0,
        //   qty: 1,
        //   size: "7.5",
        // },
      ],
      freqItems: [
        // {
        //   image: require("@/assets/perfume.png"),
        //   name: "Thriller Fleur Du Desert Perfume",
        //   amount: 299.0,
        // },
        // {
        //   image: require("@/assets/laces.jpg"),
        //   name: "Thriller Laces 'Baby Pink'",
        //   amount: 35.0,
        // },
      ],
      currentInfo: {},
      infoData: [
        {
          id: "privacy",
          title: "Privacy Policy",
          content: `
            <p>Our privacy policy describes the ways in which we collect, store, discloses and transfers, use, and protect user’s information through our Platform and application, including through THRILLER.com, and online services (collectively, the "Platform) and it is important for you to review this privacy policy. By "User information" we mean information that can be associated with a specific person that can be used to identify that person. THRILLER committed to establishing a trustworthy and secure online platform for you, we take the safeguard of your data genuinely and firmly about protecting your privacy. We understand how valuable privacy is to you, and we are committed to creating a reliable and secure environment for Users of all ages. We use the data we collect to provide you with a better experience and fulfill our objective of providing a smooth and transparent platform for E-commerce.</p>
            <p>THRILLER is an electronic web-based E-commerce Marketplace platform for providing registered Seller certain services to facilitate the marketing, sale their products, good and services by Seller to its customers (Buyer), platform is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location. You agree and acknowledge that THRILLER is only a facilitator.</p>
            <p>We collect User’s information from you when you use THRILLER and when you sign up as a User to use a service, we collect more specific personal information about you, such as your name, address, email address, telephone, mobile number, etc. by providing us with your information you expressly consent to us processing your information in accordance with the terms of our privacy policy. We do not collect sensitive personal data or information of users like the password of your email account.</p>
            <p>We may amend our privacy policy at any time by posting a revised version on the Website. The revised version will be effective at the time we post it and, following such posting, your continued use of the Site will constitute your express consent to us continuing to process you or your business entity information in accordance with the terms of our revised privacy policy. We would encourage you to check the Site regularly for the announcement of any amendments to our privacy policy. By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Platform, or any kind of our Service.</p>
            <p>When buying an item through the Service, you agree to our rules and policies for Buyers described in our FAQ, which can be found at www.Thriller.com/faq, as well as our return policy also mentioned at www.thrillerme.com/faq. You further agree that you are responsible for reading the full item listing before making a decision to purchase an item. As a buyer, you are obligated to pay for any items you Bid on when your Bid matches a seller’s Ask. WHEN YOU PLACE A BID, YOU ARE COMMITTING TO PURCHASE THE ITEM AS SOON AS YOUR BID MATCHES A SELLER’S ASK PRICE AND YOU ACKNOWLEDGE THAT PAYMENT OF THE ASK PRICE WILL BE TAKEN FROM YOUR PAYMENT METHOD. To be an eligible buyer you must have a billing address and shipping address within the United Arab.</p>
            <p>Our Platform links to other websites that may collect personally identifiable information about you, THRILLER is not responsible for the privacy practices or the content of those linked websites.</p>
            <p>Our Platform has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to strict security guidelines, protecting it against unauthorized access.</p>
            <p>By using the Platform and/ or by providing your information, you consent to the collection and use of the information you disclose on the Platform in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy and you also read and agree Users Terms.</p>
            <p>In transaction or applying for THRILLER payment or buying a service from our platform, your personal details and credit/ debit card information are secure using security features of internet security. However, we cannot guarantee encryption or the privacy of your personal details and credit/debit card information. When we receive your order, it is kept encrypted until we are ready to process it.</p>
            <p>These Terms constitute the entire and exclusive understanding and agreement between you and GOAT regarding the Service and Collective Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between you and GOAT regarding the Service and Collective Content.</p>
            <h5>DISCLOSURE OF YOUR INFORMATION</h5>
            <p>We may disclose your information that we collect, or you provide as described in this Privacy Policy:</p>
            <p>You must not:</p>
            <ul>
              <li>By registering or purchasing you as Buyer providing your contact information, you consent to the disclosure of your information to associate Service providers (Seller, Delivery Partner) and to be contacted by such service providers to provide services.</li>
              <li>To our subsidiaries, affiliates, contractors, service providers, and other third parties we use to support our business. We supply your contact information to select third parties such as data aggregators, marketers, or others for the purpose of sending you e-mail or otherwise communicating with you. We use data we have about you to determine whether you might be interested in the products, or services of a particular third party.</li>
              <li>To a service provider or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our Website users is among the assets transferred.</li>
              <li>To comply with any court order, law, or legal process, including responding to any government or regulatory request.</li>
              <li>To enforce or apply our terms of use or terms of service and other agreements, including for billing and collection purposes.</li>
              <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
            <h5>Our collection of your information</h5>
            <ul>
              <li>As part of your registration on the Site, you will be asked to provide us with certain information, such as name, address, email address, and/or telephone number, photo, and other similar information as well as some additional information about you such identifying information.</li>
              <li>We will collect transactional information based on your activities using the Site (such as the use of services); this transactional information is used solely in relation to the transactions you undertake on the Site and for no other reasons.</li>
              <li>Please note that we may use your Internet protocol (or IP) address (which is a unique number assigned to your computer server or your Internet service provider (or ISP)) to evaluate user trends and improve the administration of the Site.</li>
              <li>We may also collect information about your computer (for example, browser type) and navigation information (for example, the pages you visit on the Site) along with the times that you access the Site. Finally, we may collect additional information from or about you in other ways not specifically described here. For example, we may collect information related to your contact with our customer support team or store results when you respond to a survey. We may also collect feedback ratings and other comments relating to your use of the THRILLER.</li>
              <li>We may automatically track certain information about you based upon your behavior on our Website. We use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis</li>
            </ul>
            <p>We use 'cookie' technology (small computer files placed on your computer’s hard drive). When you go to particular website pages on the Site, the cookie identifies your browser with a unique, random number. The cookies we use do not reveal any information about you.</p>
            <p>By registering on the Site, you give us your express consent to receive promotional emails about our services and emails announcing changes to, and new features on, the Site, If, at any time.</p>
            <p>You may not use our communication tools to send spam or otherwise send content that would violate our User Agreement. Protecting your information THRILLER holds your information on servers. By providing us with your information, you consent to the transfer of your information to, and its storage on, our servers. We take every precaution to safeguard all your information from unauthorized access, use or disclosure. However, the Internet is not a secure medium and we cannot guarantee the privacy of your information. You must enter your username and password each time you want to access your account or make transactions on the Site. Choose your password carefully using unique numbers, letters, and special characters. Never share your username and password with anyone. If you are concerned that your username or password has been compromised, please contact us immediately and ensure you change your password by logging onto the User Account section of the THRILLER.</p>
            <p>You Consent By using the platform and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy. If you disclose any personal information relating to other people to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
            <h5>Contact Us</h5>
            <p>If there are any questions regarding this privacy policy, you may contact us using the information below. Email: info@THRILLER.com</p>
           `,
        },
        {
          id: "terms",
          title: "Terms & Conditions",
          content: `
           <div class="question-container">
      <div class="question">
        <h5>User’s Terms</h5>
        <p>
          THIS LEGAL USER’S TERM AGREEMENT BETWEEN YOU AND THRILLER GOVERN YOUR
          USE OF THE THRILLER PRODUCTS, SOFTWARE, SERVICES, WEB APPLICATION AND
          WEBSITE. IT IS IMPORTANT THAT YOU READ AND UNDERSTAND CAREFULLY BEFORE
          ORDRING OR USING THE PLATFORM THE FOLLOWING TERMS. BY CLICKING
          "AGREE," YOU ARE AGREEING THAT THESE TERMS WILL APPLY IF YOU CHOOSE TO
          ACCESS OR USE THE SERVICE ON THRILLER PLATFORM.
        </p>
      </div>
      <div class="question">
        <h5>GENERAL</h5>
        <p>
          THRILLER provides electronic web-based e-commerce platform and
          offering registered individual person (Seller) certain services (the "
          Services”) to facilitate the marketing, sale their products and goods
          to its Buyer ("Buyer"). Website Thriller.com is hereinafter
          collectively referred to as “Platform”. For the purposes of the Terms
          of Use, “You" shall mean the person who is accessing the Platform or
          “User” (Buyer and Seller as hereinafter collectively referred to as
          “Users”). These are the terms and conditions governing your access to
          and use of the website Thriller.com, mobile Application and its
          related sub-domains, website, services, and tools. By accepting these
          terms and conditions and by using the Platform, you represent that you
          agree to comply with these terms with THRILLER in relation to your use
          of the Platform. This User’s Terms is effective upon acceptance. If
          you do not agree to be bound by this User Terms please do not access,
          register with, or use this Platform. Before you may become or continue
          as a member of the Platform, you must read, agree with, and accept
          this User Terms and THRILLER’s Privacy Policy (the "Privacy Policy").
          You must read this User Terms and the Privacy Policy (which governs
          the protection and use of personal information about User) and access
          and read all further linked information referred to in this User
          Terms, as such information contains further terms and conditions that
          apply to you as a user of THRILLER, such linked information including
          but not limited to the Privacy Policy is hereby incorporated by
          reference into this User Terms. Registration of Membership on the
          Platform is free, however provided services is charged according to
          schedule, that can be timely changed. Changes to the fee policy shall
          be posted on the Platform and such changes shall automatically become
          effective immediately after they are posted on the Platform.
        </p>

      </div>
      <div class="question">
        <h5>Introduction</h5>
        <p>
          Thriller Middle East Free Zone LLC is a Company registered under
          the RAK Free zone, Ras Al Khaimah, United Arab Emirates and
          providing a virtual Platform for use electronic web-based
          e-commerce marketplace platforms for providing registered Seller
          certain services to facilitate the market, sale their products
          (shoes, sneakers, footwear, and any other items related to
          footwear) to its customers (Buyer) in tender format. You agree,
          understand, and acknowledge that our platform is an online
          platform that enables you to purchase products listed on the
          website at the price indicated therein at any time from any
          location. You further agree and acknowledge that THRILLER is only
          a facilitator and is not and cannot be a party to or control in
          any manner any transactions on the platform. Accordingly, the
          contract of sale of products on the platform shall be a strictly
          bipartite contract between Buyer and the Sellers.
        </p>
        <h5>Eligibility for Membership</h5>
        <p>
          The Service is available to register an individual person as a
          Buyer and a Seller. You cannot be a person barred from providing
          the Service under any law of your jurisdiction, United Arab
          Emirates, or other applicable jurisdictions, including the country
          in which you reside or from where you use the Service. By
          accepting this Agreement, you represent that you understand and
          agree to the foregoing. Account only be made with the name of a
          true and valid official name of User. THRILLER wishes to ensure
          that its members can form legally binding contracts and further
          that minors do not purchase unsuitable content. Therefore,
          membership of the Platform is not available to persons under the
          age of Twenty-one (21) years. By accepting this Agreement, you
          represent that you understand and agree to the foregoing. An
          account only be made with the name of you, and that you will
          comply with all applicable laws. THRILLER reserves the right to
          limit or withdraw access to the Platform or the membership of any
          user or account holder of THRILLER believes that a person is not
          representing the User. The Platform is not available to User whose
          membership has been suspended or withdrawn by THRILLER.&nbsp; No
          person or User may register as a member of the Website more than
          once.
        </p>
        <h5>Your Account and Registration Obligations</h5>
        <p>
          When you register as a member of the Platform you have been or
          will be required to provide certain information and register a
          username and password for use on this Platform. On becoming a
          member of the Platform, you agree: You are responsible for
          maintaining the confidentiality of and restricting access to and
          use of, your account and password, and accept responsibility for
          all activities that occur under your account and password, if
          another person uses these methods to access your account, you will
          be responsible to pay for any Items they order, and we are not
          responsible for any other losses you suffer. You agree to
          immediately notify THRILLER of any unauthorized use of your
          password or account or any other breach of security. In no event
          will THRILLER be liable for any direct, indirect, or consequential
          loss or loss of profits, goodwill, or damage whatsoever resulting
          from the disclosure of your username and/or password. You further
          acknowledge and agree that the Service is designed for individuals
          use basis and you should not share your Account and/or password
          details with others.&nbsp; You may not use another account at any
          time, without the express permission of the account holder. You
          agree to reimburse THRILLER for any improper, unauthorized, or
          illegal use of your account by you or by any person obtaining
          access to the Platform, services or otherwise by using your
          designated username and password, whether or not you authorized
          such access. You will provide true, accurate, current, and
          complete information about yourself as prompted by THRILLER's
          registration form. You will maintain and promptly update the
          registration data to keep it true, accurate, current, and
          complete. If you provide any information that is untrue,
          inaccurate, not current, or incomplete or if THRILLER has
          reasonable grounds to suspect that such information is untrue,
          inaccurate, not current or incomplete, or not in accordance with
          this User Terms, without prejudice to any other rights and
          remedies of THRILLER under this User Terms or at law, THRILLER has
          the right to indefinitely suspend, limit or withdraw your access
          to the Platform and/or your membership of the Platform. THRILLER
          may (in its sole discretion and at any time), make any inquiries
          it considers necessary (whether directly or through a third
          party), and request that you provide it with further information
          or documentation, including without limitation to verify your
          identity. Without limiting the foregoing, your information or
          items detail include your item receipt/ Invoice showing ownership
          to you. You agree that any sort of wrong and illegal act you may
          be liable under Cybersecurity laws and other applicable criminal
          and Civil laws.&nbsp; You acknowledge and agree that if you do
          not, THRILLER without liability may limit, suspend, or withdraw
          your access to the Platform and/or your membership of the
          Platform. We also reserve the right to cancel
          unconfirmed/unverified accounts or accounts that have been
          inactive for a long time. If your use of the Service or other
          conduct intentionally or unintentionally threatens THRILLER’s
          ability to provide the Service or other systems, THRILLER shall be
          entitled to take all reasonable steps to protect the Service and
          THRILLER’s systems, which may include suspension of your access to
          the Service. You agree that your Account is non-transferable.
        </p>
        </div>
      <div class="question">
        <h5>Access to Your Account and Content</h5>
        <p>
          THRILLER reserves the right to take steps THRILLER believes is
          reasonably necessary or appropriate to enforce and/or verify
          compliance with any part of this Agreement. You acknowledge and agree
          that THRILLER may, without liability to you, access, use, preserve
          and/or disclose your Account information and Content to law
          enforcement authorities, government officials, and/or a third party,
          as THRILLER believes is reasonably necessary or appropriate, if
          legally required to do so or if THRILLER has a good faith belief that
          such access, use, disclosure, or preservation is reasonably necessary
          to: (a) comply with legal process or request; (b) enforce this
          Agreement, including investigation of any potential violation thereof;
          (c) detect, prevent or otherwise address security, fraud or technical
          issues; or (d) protect the rights, property or safety of THRILLER, its
          users, a third party, or the public as required or permitted by law.
        </p>
      </div>
      <div class="question">
        <h5>License from You</h5>
        <p>
          Except for material we may license to you, THRILLER does not claim
          ownership of the materials and/or content you submit or make available
          on the Service. However, by submitting or posting such Content on
          areas of the Service that are accessible by the public or other users
          with we share such Content, you grant THRILLER a worldwide,
          royalty-free, non-exclusive license to use, distribute, reproduce,
          modify, adapt, publish, translate, publicly perform, and publicly
          display such Content on the Service solely for the purpose for which
          such Content was submitted or made available, without any compensation
          or obligation to you. You agree that any Content submitted or posted
          by you shall be your sole responsibility, shall not infringe or
          violate any intellectual property rights of any other party or violate
          any laws, contribute to, or encourage infringing or otherwise unlawful
          conduct, or otherwise be obscene, objectionable, or in poor taste. You
          license that all items posted on the website are genuine and User has
          right intellectually property rights on them, and they are not stolen
          or other’s property. You can only use the services of the Website if
          they do not conflict with or violate the applicable laws of your
          jurisdiction and other related jurisdictions. By creating an account,
          you agree that you may receive communications from THRILLER, such as
          newsletters, special offers, and account reminders and updates by
          Email, SMS, Push Notification, text message etc. You also understand
          that you can remove yourself from these email communications by
          clicking the "Unsubscribe" link in the footer of the actual email and
          will not block email subscription.
        </p>
      </div>
      <div class="question">
        <h5>Trade</h5>
        <p>
          A buyer may place a Bid on an item for sale through the platform and a
          Bid matches an Ask, the buyer is obligated to pay for that item, it
          needs to be accepted by us and the Seller before it is confirmed. We
          will send you a notification if your order has been accepted. The
          contract for the supply of any Item you have ordered comes into
          existence when we send the Confirmation Notice. You are responsible
          for paying for all Items ordered using your account, and for related
          delivery charges, and for complying with these Terms, even if you have
          ordered the Item for someone else. All Items are subject to
          availability. In the situations the contract is done between buyer and
          seller under the condition that the Items’ authenticity will be
          approved by Thriller&nbsp;Seller decides their own operating hours.
          That means that the availability of Service depends on the seller.
          <br />
          You may select these following options on our platform: <br />
          Matching: Buyer may place bids on listed items on the platform, seller
          may place an item for a fixed or lowest price, and the seller makes a
          biding offer to complete a contract for this item at mentioned fixed
          or lowest price. Buyer may place a bid offering a fixed or highest
          price for a specific item; the buyer makes a binding offer to conclude
          a contract for this item at this fixed or highest price. <br />
          Buy now: The seller may also activate the “buy now” function. If the
          buyer uses the “buy now” feature, which will attempt to automatically
          match a buyer with a seller at the lowest overall ask price, the
          contract will be concluded at the moment where the buyer clicks on the
          Buy now button and transaction is successfully completed. <br />
          Sell now: If the seller uses the “sell now” feature, which will
          attempt to automatically match a seller with a buyer at the highest
          bid, the contract will be concluded at the moment where the seller
          clicks on the “Sell now” button and the transaction is successfully
          completed. <br />
          Thriller provides a platform to conclude the sale on behalf of buyer
          and seller. Because sellers set prices, they may be higher than retail
          value of the products sold on our platform. Any item valuations
          displayed through the Services are estimates only. Thriller does not
          guarantee that any item will sell. Thriller reserves the right to
          investigate complaints and violations of these Terms and, to the
          extent permitted by applicable law, may take any actions we deem
          appropriate, including suspending a buyer or seller account and
          charging your payment method for costs we incur as a result of the
          violation.
        </p>
      </div>
      <div class="question">
        <h5>Prices and Payment</h5>
        <ul>
          <li>
            <p>
              · Price of the product will be decided by the Seller. You confirm
              that Prices can also change at any time at the discretion of the
              Seller, we or the seller may also charge you a delivery fee. This
              will be notified to you during the order process before you
              complete your order. The total price of your order will be set out
              on the checkout page on Platform, including the prices of Items
              and Delivery and applicable Service Fees and taxes.
            </p>
          </li>
          <li>
            <p>
              · All amounts payable by Customer must be received in full prior
              to commencement of Service. Customer will be responsible for the
              payment of any present or future sales, VAT, or other similar tax
              applicable to the services. Taxes will be applied on the Services
              according to the law of jurisdiction.
            </p>
          </li>
          <li>
            <p>
              · Payment for all Items and deliveries can be made on our platform
              by credit or debit card, or other payment method made available by
              THRILLER. Prices is included VAT. Once your order has been
              confirmed your credit or debit card will be authorised and the
              total amount marked for payment (in respect of all payments made
              by credit or debit cards). Payment is made directly to us acting
              as agent on behalf of the seller only.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Transactions Between Buyers and Sellers</h5>
        <p>
          Through the Platform, THRILLER provides electronic web-based platforms
          for exchanging information between buyers and sellers of products and
          services. &nbsp;THRILLER additionally provides electronic web-based
          transaction platforms for Sellers to place, accept, conclude, manage,
          and fulfil orders for the provision of products and services online
          within the Platform. &nbsp;However, for any Services, THRILLER does
          not represent either the seller or the buyer in specific transactions.
          THRILLER does not control and is not liable or responsible for the
          quality, safety, lawfulness or availability of the products or
          services offered for sale on the Platform, the ability of the sellers
          to complete a sale or the ability of buyers to complete a purchase.
        </p>
        <ul>
          <li>
            <p>
              . Users are hereby made aware that there may be risks of dealing
              with people acting under false pretences. THRILLER uses several
              techniques to verify the accuracy of certain information our
              paying Users provide us when they register for a paying membership
              service on the Platform. &nbsp;However, because user verification
              on the Internet is difficult, THRILLER cannot and does not confirm
              each User's purported identity (including, without limitation,
              paying Sellers). We encourage you to use various means, as well as
              common sense, to evaluate with whom you are dealing.
            </p>
          </li>
          <li>
            <p>
              . Thriller acts as a commercial agent to complete the sale on
              behalf of each buyer and seller involved in each deal. Because
              sellers set prices, they may be higher than retail value of the
              products sold on our platform. Any item valuations displayed
              through the Services are estimates only. Thriller does not
              guarantee that any item will sell. Thriller reserves the right to
              investigate complaints and violations of these Terms and, to the
              extent permitted by applicable law, may take any actions we deem
              appropriate, including suspending a buyer or seller account and
              charging your payment method for costs we incur as a result of the
              violation.
            </p>
          </li>
          <li>
            <p>
              · Buyers and sellers accessing or using the Platform or Services
              shall assume the risks of conducting any purchase and sale
              transactions in connection with or through the Platform or
              Services. Buyer and sellers accessing or using the Site or
              Services shall also fully assume all risks of liability or harm of
              any kind arising out of or in connection with any subsequent
              activity relating to the products or services that are the subject
              of the transactions on the Platform. Examples of such risks shall
              include, but are not limited to, mis-representation of products
              and services, fraudulent schemes, unsatisfactory product quality,
              failure to meet specifications, defective or dangerous products,
              unlawful products, delay or default in delivery or payment, cost
              mis-calculations, breach of warranty, breach of contract,
              transportation accidents, the risk that the manufacture,
              importation, export, distribution, offer, display, purchase, sale
              and/or use of products or services offered or displayed on the
              Platform may violate or may be asserted to violate Third Party
              Rights, and the risk that Users may incur costs of defence or
              other costs in connection with third parties’ assertion of Third
              Party Rights, or in connection with any claims by any party that
              they are entitled indemnification in relation to the assertion of
              rights, demands or claims by claimants of Third Party Rights.
              Examples of such risks also include the risk of claims from
              consumers, other purchasers, end-users of products or
              other&nbsp;third parties that they have suffered injuries or harm
              from their use of the products obtained through the Platform or
              Services. All of the foregoing risks are referred to as
              "Transaction Risks". THRILLER is not liable or responsible for any
              damages, claims, liabilities, costs, harm, inconveniences,
              business disruptions or expenditures of any kind that may arise a
              result of or in connection with any Transaction Risks.
            </p>
          </li>
          <li>
            <p>
              · In the event that any User has a dispute with any party to a
              transaction, such User agrees to release and indemnify THRILLER
              (and our agents, affiliates, directors, officers, and employees)
              from all claims, demands, actions, proceedings, costs, expenses,
              and damages (including without limitation any actual, special,
              incidental or consequential damages) arising out of or in
              connection with such dispute or the transaction.
            </p>
          </li>
          <li>
            <p>
              · If a buyer receives an item that it believes to be counterfeit,
              the buyer must notify Thriller in writing within 3 days after
              receiving the item, and we will commence an investigation into the
              item. The buyer shall cooperate with us in the investigation and
              final disposition of the item, including providing photographs and
              other evidence of the item, providing the item to law enforcement,
              destroying the item, or delivering the item back to us, at our
              direction. If we elect to have the buyer destroy the item, the
              buyer shall provide reasonable proof of destruction to us. We will
              refund all fees and costs paid by the buyer for the item.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Delivery</h5>
        <p>
          As part of the purchase transaction, the seller will send the item to
          Thriller and Thriller will visually inspect the item and use
          commercially reasonable efforts to confirm it is authentic and
          according to our condition standards. If Thriller determines the item
          is authentic and meets our condition standards, Thriller will ship the
          item to the buyer. If Thriller cannot reasonably confirm the
          authenticity of the item or determines it is not authentic or does not
          meet our condition standards, then we will notify the buyer and the
          seller that the item has not passed authentication and will issue a
          refund to the buyer. Additionally, Thriller has the right to reject
          any item for any reason, including but not limited to, authenticity,
          failure to meet our condition standards, or damage of the item.
          Thriller policy requires sellers to ship items within the specified
          timeframe provided in the sale confirmation email after purchase.
          However, because Thriller cannot control the amount of time it takes
          for the item to reach Thriller, we cannot guarantee a specific
          delivery timeframe for any items and therefore, buyer is not permitted
          to cancel the purchase if the item does not ship from the seller
          within the specified timeframe in the sale confirmation email after
          purchase. Once we receive the item from the seller it generally takes
          1-2 business days for us to authenticate the item and ship it to a
          buyer. If a buyer does not receive an item, the buyer shall promptly
          report the issue to Thriller within 2 days of the latest estimated
          delivery date. Thriller has the right, in our sole and absolute
          discretion to refuse to accept returns or other refunds and/or to
          charge restocking fees for returned or abandoned items. If a buyer or
          seller refuses to accept delivery of a package sent to his/her address
          on file, Thriller will consider the property abandoned after thirty
          (30) days and may charge the buyer or seller our reasonable expenses
          incurred due to such refusal to accept delivery.
        </p>
      </div>
      <div class="question">
        <h5>Cancellation, Returns and Refund Policy</h5>
        <p>
          Cancelation, Return and Refund of the item purchased by the Buyer from
          the Seller on the Platform will be regulated by the following
          conditions:
        </p>
        <ul>
          <li><p>. Item was defective.</p></li>
          <li><p>. Item was damaged during the Shipping.</p></li>
          <li><p>. Products was / were missing.</p></li>
          <li><p>. Wrong item was sent by the Seller.</p></li>
          <li>
            <p>
              . Return could also result in refund of money in most of the
              cases.
            </p>
          </li>
          <li>
            <p>
              . If the Seller chooses to return applicable on the review page of
              upon listing an item, the seller agrees to accept the return if
              the return is made within the allotted time period and with
              reasonable cause.
            </p>
          </li>
          <li>
            <p>
              . We encourage the Buyer to review the listing before making the
              purchase decision. In case Buyer orders a wrong item, Buyer shall
              not be entitled to any return/refund.
            </p>
          </li>
          <li>
            <p>
              . Buyer needs to raise the return request within the return period
              applicable to the respective product. Once Buyer has raised a
              return request by contacting Us, Seller while closing the return
              ticket can select to replace after shipment collection or Refund
              after shipment collection. Buyer needs to raise the return request
              within the return period applicable to the respective product.
              Once Buyer has raised a return request by contacting Us, We will
              arrange the pickup of the item and, return to the seller, and
              refund to the buyer.
            </p>
          </li>
          <li>
            <p>
              . All products listed under a particular category may not have the
              same returns policy.
            </p>
          </li>
          <li>
            <p>
              . All requests for refunds will be reviewed on a case by case
              basis and awarded at THRILLER’s sole discretion.
            </p>
          </li>
          <li>
            <p>
              . All approved refunds will be made through the original mode of
              payment only.
            </p>
          </li>
          <li>
            <p>
              . Where a bank is not supported for processing refunds, you will
              be required to share alternate bank account details with us for
              processing the refund.
            </p>
          </li>
          <li>
            <p>
              . Refund shall be conditional and shall be with recourse available
              to THRILLER in case of any misuse by Buyer.
            </p>
          </li>
          <li>
            <p>
              . We may also request you for additional documents for
              verification and Refund shall be subject to Buyer complying with
              Policies.
            </p>
          </li>
          <li>
            <p>
              . In case of a dispute where the Seller is unable to provide a
              refund or a replacement, THRILLER will actively work towards
              reaching a resolution and THRILLER’s decision will be final and
              obligatory.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Seller’s obligations</h5>
        <ul>
          <li>
            <p>
              . By listing an item for sale (each posting, a “New Asking
              Price”), you are making a binding offer to sell that specific item
              to a buyer who purchases the item for the Ask price you have
              specified and to ship the item in accordance with our&nbsp;FAQ as
              updated from time to time. When a buyer accepts your offer by
              purchasing your item through our Services, you are contractually
              and legally bound to deliver that exact item for the specified
              price. Sellers must ship items via the shipping carrier designated
              by Thriller within the specified timeframe provided in the sale
              confirmation email and must obtain a receipt from the shipping
              carrier to verify shipment of item. Sellers must ship an item sold
              on our Sites from the country listed as your address on file
              (e.g., if your address on file is located in Dubai, you cannot use
              the shipping label provided to you to ship the item from Abu
              Dhabi). THRILLER will provide you with shipping instructions with
              such carrier of steps to perform on the seller’s dashboard, after
              your Asking Price is matched by a buyer. You are obligated to
              monitor your inventory and ensure all listings are accurate. Once
              an Offer and an Asking Price are matched, under no circumstances
              may a seller cancel the listing. Failure to fulfil your orders
              will result in additional charges to you, including a minimum of
              AED 50 or an amount up to 15% of the transaction amount, in
              Thriller’s sole discretion. If a seller fails to deliver items to
              THRILLER in accordance with these Terms, THRILLER reserves the
              right to do any or all of the following, in its sole discretion:
              (i) charge seller’s payment method a minimum of 50AED or an amount
              up to 20% of the transaction amount, in THRILLERS sole discretion,
              as a service fee; (ii) remove any or all of seller’s listings from
              the Services; (iii) cancel any or all of seller’s orders pending
              through the Services; (iv) withhold any payments due to seller;
              (v) place limits on seller’s buying and selling privileges; (vi)
              charge seller’s payment method for costs, expenses and fees
              incurred by THRILLER as a result of seller’s action or inaction,
              including charging seller for the cost of a replacement, provided
              to the buyer, reprinting fees incurred by Thriller, rerouting
              charges imposed by carriers, and refunds to the buyer; and (vii)
              temporarily or permanently suspend seller’s account.
            </p>
          </li>
          <li>
            <p>
              . As a registered Seller, you are allowed to list item for sale on
              the Platform in accordance with the Policies which are
              incorporated by way of reference in this Terms of Use. You must be
              legally able to sell the items you list for sale on our Platform.
              You must ensure that the listed items do not infringe upon the
              intellectual property, trade secret or other proprietary rights or
              rights of publicity or privacy rights of third parties. All listed
              items must be listed in an appropriate category on the Platform.
              All listed items must be kept in stock for successful fulfilment
              of sales.
            </p>
          </li>
          <li>
            <p>
              . As a registered Seller, you are allowed to list item for sale on
              the Platform in accordance with the Policies which are
              incorporated by way of reference in this Terms of Use. You must be
              legally able to sell the items you list for sale on our Platform.
              You must ensure that the listed items do not infringe upon the
              intellectual property, trade secret or other proprietary rights or
              rights of publicity or privacy rights of third parties. All listed
              items must be listed in an appropriate category on the Platform.
              All listed items must be kept in stock for successful fulfilment
              of sales.
            </p>
          </li>
          <li>
            <p>
              If a seller provides a counterfeit item or attempts to defraud any
              buyer&nbsp;Thriller will charge seller’s payment method a minimum
              of AED 50.00 or an amount up to 20% of the transaction amount as a
              service fee and shipping charges.
            </p>
          </li>
          <li>
            <p>
              . You have specifically authorized THRILLER or its service
              providers to collect, process, facilitate, and remit payments
              and/or the transaction price electronically. You understand,
              accept, and agree that the payment facility provided by THRILLER
              is neither a banking nor financial service, but merely a
              facilitator providing an electronic, automated online electronic
              payment facility for receiving payment.
            </p>
          </li>
          <li>
            <p>
              . Sellers oblige to compliance All consumer rules, laws, bylaws
              related provisions to their offering product, item, or material
              according to their local Jurisdiction requirement and if needed
              keep insurance of any kind damages reimbursement.
            </p>
          </li>
          <li>
            <p>
              . The listing description of the item must not be misleading and
              must describe actual condition of the product. If the item
              description does not match the actual condition of the item, you
              agree to refund any amounts that you may have received from the
              Buyer. You agree not to list a single product in multiple
              quantities across various categories on the Platform. Seller must
              apply all rules, laws applicable to consumer rights and
              protection.
            </p>
          </li>
          <li>
            <p>
              . You will not post or sell any item that is restricted or
              prohibited by law of United Arab Emirates and any local law in any
              country or jurisdiction, as user or seller, you are responsible
              for ensuring that you are not posting an item that is prohibited
              by law in any jurisdiction, including but not limited illicit
              drugs, precursors, drug paraphernalia, flammable, explosive and
              hazardous chemicals, firearms, munitions, weapons, government, law
              enforcement, military issued items, medical drugs, medical
              devices, adult, obscene materials, circumvention devices, other
              equipment used for illicit purposes, illegal services,
              collections, artifacts, precious metals, human parts, human
              remains, protected flora, fauna, offensive material, information
              detrimental to national security, tobacco products, gambling
              equipment, unauthorised intellectual property rights (IPR)
              ,replica and counterfeit items, unauthorized copies of
              intellectual property.
            </p>
          </li>
          <li>
            <p>
              . Each Seller represents, warrants and agrees that (1) you shall
              be solely responsible for obtaining all necessary third party
              licenses and permissions regarding any User Content that you
              submit, post or display; (2) any User Content that you submit,
              post or display does not infringe or violate any of the copyright,
              patent, trademark, trade name, trade secrets or any other personal
              or proprietary rights of any third party (“Third Party Rights”);
              (3) you have the right and authority to sell, trade, distribute or
              export or offer to sell, trade, distribute or export the products
              or services described in the User Content and such sale, trade,
              distribution or export or offer does not violate any Third Party
              Rights and (4) you and your affiliates are not the subject of any
              trade restrictions, sanctions or other legal restrictions enacted
              by any country, international organization or jurisdiction.
            </p>
          </li>
          <li>
            <p>
              . Each Seller further represents, warrants, and agrees that Seller
              shall/are:<br />
              1. carry on your activities on the Platform in compliance with any
              applicable laws and regulations;<br />2. conduct your business
              transactions with other users of the Platform in good faith; carry
              on your activities in accordance with the Terms and any applicable
              Additional Agreements;<br />3. not use the Services or Platform to
              defraud any person or entity (including without limitation sale of
              stolen items, use of stolen credit/debit cards); not impersonate
              any person or entity, misrepresent yourself or your affiliation
              with any person or entity;<br />4. not engage in spamming or
              phishing;<br />5. not engage in any other unlawful activities
              (including without limitation those which would constitute a
              criminal offence, give rise to civil liability, etc) or encourage
              or abet any unlawful activities;<br />6. not involve any computer
              viruses or other destructive devices and codes that have the
              effect of damaging, interfering with, intercepting, or
              expropriating any software or hardware system, data, or personal
              information;<br />7. not involve any scheme to undermine the
              integrity of the data, systems or networks used by THRILLER and/or
              any user of the Platform or gain unauthorized access to such data,
              systems or networks; not, and your director(s), officer(s),
              controlling party/ies, affiliates and legal jurisdiction in which
              any of the foregoing persons or entities is organized or has
              operations are not, persons or entities that are subject to any
              economic or fraud sanctions of any governmental, international or
              regulatory entities; and<br />8. not engage in any activities that
              would otherwise create any liability for THRILLER or our
              affiliates.<br />9. Seller may not use the Platform, Services, or
              Seller account to engage in activities which are identical or
              similar to THRILLER e-commerce marketplace business.<br />10.
              Seller agrees to provide all necessary information, materials, and
              approval, and render all reasonable assistance and cooperation
              necessary for THRILLER’s provision of the Services, evaluating
              whether Seller has breached the Terms and/or handling any
              complaint against the Seller. &nbsp;If Seller’s failure to do so
              results in delay in, or suspension or termination of, the
              provision of any Service, THRILLER shall not be obliged to extend
              the relevant service period nor be liable for any loss or damages
              arising out of or in connection with such delay, suspension, or
              termination.<br />11. Seller acknowledges and agrees that THRILLER
              reserves the right to but shall not be required to actively
              monitor or exercise any editorial control whatsoever over the
              content of any message or material or information (including User
              Content) created, obtained or accessible through the Services or
              Platform. THRILLER does not endorse, verify, or otherwise certify
              the contents of any comments or other material, or information
              (including User Content) created, submitted, posted, displayed, or
              otherwise made by any Seller. Each Seller is solely responsible
              for the contents of their communications and may be held legally
              liable or accountable for the content of their comments or other
              material or information.<br />12. Seller acknowledges and agrees
              that each Seller is solely responsible for observing applicable
              laws and regulations in its respective jurisdictions to ensure
              that all access and use of the Site and Services are in compliance
              with the same.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Service Charge</h5>
        <ul>
          <li>
            <p>
              . In consideration for the Services of our platform provided to
              Seller will be charged agreed fee on every sale transaction
              according to separate scheduled and agreed terms, Seller agreed to
              pay transaction fee, charges with applicable taxes.
            </p>
          </li>
          <li>
            <p>
              . You as a Seller agree that you will pay for Delivery charges and
              Pick up charges for return items.
            </p>
          </li>
          <li>
            <p>
              . Seller agrees that all accounts, charges, commission, or fee of
              transaction will be settled according to return policy and after
              the lapse of return time period and agreed terms and period.
            </p>
          </li>
          <li>
            <p>
              . THRILLER shall have right to revise the fee, charges, or
              commission policy any time, amended policy will be posted and
              declared on the Platform.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Use of the Platform</h5>
        <p>
          While using the Platform, you will not: post Information or content or
          list items in an inappropriate category or areas on our Platform; post
          any content which insults the UAE state, against the UAE laws or
          Islam, such as any partial or full nudity (including simply “beach
          scene” photographs), fire-arms, drugs or alcohol; post items you do
          not have a right to link to or include; post items that are
          concurrently listed for services on a Website other than the Platform;
          post Information that is (in our sole discretion) false, fraudulent,
          inaccurate, misleading, libellous, defamatory, slanderous, unlawfully
          threatening or would be reasonably considered to constitute
          harassment; post comments, questions or answers that are not factual
          in nature including without limitation make any racist comments, use
          profanity, abuse another user, disrespect another's culture or make
          any other derogatory or inappropriate comments; post counterfeit or
          stolen items; post Information or items which infringe any third
          party's intellectual property rights, other proprietary rights or
          right to privacy; post obscene Information or content, including but
          not limited to pornography or any representation which may (in our
          sole discretion) be considered indecent; post Information or content
          which may (in our sole discretion) constitute offensive or critical
          political content or content that is contrary to the public interest;
          post any Information or content or list items which may (in our sole
          discretion) be considered culturally or religiously offensive in any
          way; post any Information or content or list items which (in our sole
          discretion) may not be considered to be in compliance with general
          Islamic Shari’ah law, rules, morals, values, ethics and traditions;
          post Information or content threaten national security;&nbsp; post
          Information or content or list Services which may (in our sole
          discretion) constitute or be considered to promote gambling,
          terrorism, racism or any kind of criminal or illegal act.
        </p>
      </div>
      <div class="question">
        <h5>Changes to Content</h5>
        <p>
          You understand that in order to provide the Service and make your
          Content available thereon, THRILLER may transmit your Content across
          various public networks, in various media, and modify or change your
          Content to comply with technical requirements of connecting networks
          or devices or computers. You further agree that THRILLER may limit,
          suspend, or withdraw your access to the Platform and/or a user's
          membership of the Platform or remove hosted content.
        </p>
      </div>
      <div class="question">
        <h5>Amendments to this User Terms</h5>
        <p>
          The THRILLER shall have the right to amend, vary or modify the Terms
          of User, Privacy Commitment of THRILLER Website and these Additional
          Terms of Use governing your use of the Service, without giving any
          prior notice to you and such amendment shall be effective immediately
          upon inclusion/posting of such amendment on THRILLER Website. You
          agree to be bound by the revised Terms. <br />You may be required to
          enter into separate agreement(s), whether online or offline, with
          THRILLER or our affiliate for any Service (or features within the
          Services) (each an “Additional Agreement”). If there is any conflict
          or inconsistency between the Terms and an Additional Agreement, the
          Additional Agreement shall take precedence over the Terms only in
          relation to that Service (or feature within the Service) concerned.
        </p>
      </div>
      <div class="question">
        <h5>Intellectually rights</h5>
        <p>
          The "THRILLER" and related logos, and other words and logos on the
          Platform are either unregistered trademarks or registered trademarks
          of THRILLER and are protected by international trademark and other
          intellectual property rights and laws. All other trademarks not owned
          by THRILLER that appear on the Platform are the property of their
          respective owners, who may or may not be affiliated with, connected
          to, or sponsored by THRILLER.
        </p>
      </div>
      <div class="question">
        <h5>Indemnity and Warranty</h5>
        <p>
          You agree to defend, indemnify, and waive THRILLER and its affiliates,
          directors, Contractors, officers, employees, agents and suppliers
          harmless from any and all claims, demands, actions, proceedings,
          losses, liabilities, damages, costs, expenses (including reasonable
          legal costs and expenses), howsoever suffered or incurred due to or
          arising out of your breach of this User Terms, or your violation of
          any law or the rights of a third party. &nbsp;You acknowledge that you
          are responsible for all use of the Service using your Account and that
          these Terms apply to any and all usage of your Account. You agree to
          comply with this Terms and to defend, indemnify and hold harmless
          THRILLER from and against any and all claims and demands arising from
          the usage of your Account, whether or not such usage is expressly
          authorized by you. <br />
          You agree and acknowledge that THRILLER makes no warranties or
          representations about the accuracy or completeness of THRILLER
          Website's content or the content of any Platforms linked to THRILLER
          Website and assumes no liability or responsibility for any (i) Errors,
          mistakes, or inaccuracies of content, (ii) Personal injury or property
          damage, of any nature whatsoever, resulting from your access to and
          use of THRILLER, (iii) Any unauthorized access to or use of THRILLER
          servers and/or any and all personal information and/or financial
          information stored therein in any form and/or in any manner, (iv) any
          interruption or cessation of transmission to or from THRILLER Website,
          (iv) Any bugs, viruses, Trojan horses, or the like unauthorized tools
          or software which may be transmitted to or through THRILLER Website by
          any third party, and/or (v) Any errors or omissions in any content or
          for any loss or damage of any kind incurred as a result of the use of
          any content posted, emailed, transmitted, or otherwise made available
          through THRILLER Website. THRILLER does not warrant, endorse,
          guarantee, or assume responsibility for any product or service
          advertised or offered by a third party through the THRILLER Website or
          any associated or hyperlinked Website and THRILLER shall not be a
          party to or in any way be responsible or accountable for monitoring
          any transaction between you and third-party.
        </p>
      </div>
      <div class="question">
        <h5>LIMITATION OF LIABILITY</h5>
        <p>
          You expressly understand and agree that, to the maximum extent
          permissible under applicable law, the company shall not be liable for
          any indirect, incidental, special, consequential, or exemplary
          damages, including but not limited to, damages for loss of profits,
          revenue, goodwill, use, data, or other intangible losses (even if you
          have been advised of the possibility of such damages). the company
          provides no guarantees of server uptime or applications working
          properly. in no event will the company’s aggregate liability to you
          for any claims, including under tort, contract or otherwise, exceed
          the amount of fees paid by you to the THRILLER. The foregoing
          limitation of liability shall apply to the fullest extent permitted by
          law in the applicable local and/or international jurisdiction.
        </p>
      </div>
      <div class="question">
        <h5>Default of Services</h5>
        <p>
          THRILLER,at FDBC0948 Compass Building, Al Shohada Road, AL Hamra
          Industrial Zone-FZ, Ras Al Khaimah, United Arab Emirates shall not be
          held liable or considered in default of its service agreements,
          implied or express, when the delay of performance is caused by
          circumstances beyond our reasonable control and occurring without our
          fault or negligence, such as but not limited to Failure by third party
          subcontractors, suppliers and/or carriers to provide agreed-upon
          service(s) Problems or failures in accessing on-line information
          sources Problems or failures with phone lines, internet access
          service, web Platform hosting service, or other third-party service
          providers, such as Server failures and Software glitches,&nbsp;
          Widespread virus problems.
        </p>
      </div>
      <div class="question">
        <h5>Force Majeure</h5>
        <p>
          Under no circumstances shall THRILLER be held liable for any delay or
          failure or disruption of the content or the Services accessed or
          delivered through the Platform or the creation or fulfilment of
          contracts resulting directly or indirectly from acts of nature, forces
          or causes beyond our reasonable control, including without limitation,
          Internet failures, computer, telecommunications or any other equipment
          failures, electrical power failures, strikes, labor disputes, riots,
          insurrections, civil disturbances, shortages of labor or materials,
          fires, flood, storms, explosions, acts of God, war, governmental
          actions, orders of domestic or foreign courts or tribunals, or
          non-performance of third parties or any suspension or disruption of
          transportation or business operation (including but not limited to
          delays or disruption of the resumption of work or operation ordered by
          any government agency) in the event of a national or regional spread
          of epidemic or pandemic.
        </p>
      </div>
      <div class="question">
        <h5>Disputes</h5>
        <p>
          If any dispute, claim, controversy, or differences arising out of or
          in connection with or in relation to this User Terms Agreement,
          including any question regarding the formation, existence, scope,
          performance, interpretation, validity or termination of this User
          Terms or clauses, or any question regarding the legal relationships
          established by this User Terms or the consequences of its nullity,
          then the parties shall first attempt amicably to settle the Dispute
          through good faith. The relationship between you and THRILLER shall be
          governed by the laws of the United Arab Emirates, In the event that a
          Dispute has not been settled amicably by the relevant parties, the
          Dispute shall be referred to Dubai courts. You and THRILLER agree to
          submit to the exclusive jurisdiction of the dispute will be Dubai
          Courts, United Arab Emirates to resolve any dispute or claim arising
          from this Agreement.
        </p>
      </div>
      <div class="question">
        <h5>Termination</h5>
        <ul>
          <li>
            <h5>Termination by Buyer</h5>
            <p>
              Buyer may terminate user Account and/or stop using the Service at
              any time. If a buyer wishes to terminate his account, simply send
              an email to thefuture@thrillerme.com.
            </p>
          </li>
          <li>
            <h5>Termination by Seller</h5>
            <p>
              Seller can terminate this Agreement by 30 days’ notice. All or any
              of the Transaction, fee, charges remaining will be completed
              perfectly by Seller before the termination. All Payment and
              accounts will be settled by Seller within 30 days of termination
              of this User agreement. Seller will refund and accept all return
              items after termination of this agreement.
            </p>
          </li>
          <li>
            <h5>Termination by THRILLER</h5>
            <p>
              THRILLER may at any time, under certain circumstances and without
              prior notice, immediately terminate or suspend all or a portion of
              your Account and/or access to the Service at any time, under
              certain circumstances and without, immediately without liability:
              limit your activity, remove your bids, end your listings, warn
              other users of your actions, temporarily / indefinitely suspend,
              limit or withdraw your membership, and/or limit or withdraw your
              access to the Site, end your listings, warn other users of your
              actions, temporarily / indefinitely suspend, limit or withdraw
              your membership to the Site Cause for such termination shall
              include: <br />1. Violations of this Agreement or any other
              policies or guidelines that are referenced herein and/or posted on
              the Service.<br />2. A request by you to cancel or terminate your
              Account.<br />3. A request and/or order from law enforcement, a
              judicial body, or other government agency.<br />4. Where provision
              of the Service to you is or may become unlawful.<br />5.
              Unexpected technical or security issues or problems.<br />6. Your
              participation in fraudulent or illegal activities.<br />7. Failure
              to pay any fees, Charges owed by you in relation to the
              Service.<br />8. Negative feedback from Customers/ Buyers.<br />Any
              such termination or suspension shall be made by THRILLER in its
              sole discretion and THRILLER will not be responsible to you or any
              third party for any damages that may result or arise out of such
              termination or suspension of your Account and/or access to the
              Service. In addition, THRILLER may terminate your Account WITHOUT
              prior notice. Any such termination or suspension shall be made by
              THRILLER in its sole discretion and THRILLER will not be
              responsible to you or any third party for any damages that may
              result or arise out of such termination or suspension of your
              Account and/or access to the Service.
            </p>
          </li>
          <li>
            <h5>Effects of Termination</h5>
            <p>
              Upon termination of your Account, you will lose all access to the
              Service and any portions thereof, including, but not limited to,
              your Account, THRILLER ID, email account, and Content. In
              addition, after a period of time, THRILLER may delete information
              and data stored in or as a part of your account. Any individual
              components of the Service that you may have used subject to
              separate software license agreements will also be terminated in
              accordance with those license agreements. You further acknowledge
              and agree that THRILLER shall not be responsible or liable in any
              way for any damages you incur or allege to have incurred, either
              directly or indirectly, as a result of your use and/or reliance
              upon any such Content, advertising, products, or materials on or
              available from such sites or resources. All or any of the
              Transaction, fee, and charges remaining will be settled in notice
              time.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Notice</h5>
        <p>
          None of the provisions of this User Terms shall be deemed to
          constitute a partnership or agency between you and THRILLER and you
          shall have no authority to bind THRILLER in any manner whatsoever.
          Except as explicitly stated otherwise, any notices to THRILLER from
          you shall be given by you by THRILLER with a physical copy sent to us
          by email and courier, such notice deemed given on confirmation of its
          receipt to you by THRILLER by return email. Any notices to you from
          THRILLER shall be given by notices posted on the Platform or by email
          to the email address you provide to us during the registration process
          and shall be deemed to be received by you 48 hours after any such
          email is sent.
        </p>
      </div>
      <div class="question">
        <h5>Links to third party</h5>
        <p>
          The Web Platform contains links or other connections to third party
          web Platforms. THRILLER provides these links only as a convenience to
          you and does not endorse, and is not responsible for, the contents on
          such linked Platforms. You accept any terms and conditions imposed by
          that third-party. Further, The THRILLER is not responsible for any
          viruses’ accesses through said third-party. If you decide to access
          linked third-party Web Platforms, you do so at your own risk.
        </p>
      </div>
      <div class="question">
        <h5>Transfer of Rights and Obligations</h5>
        <p>
          You hereby grant THRILLER the right to, and irrevocably acknowledge
          and agree that THRILLER may at any time, transfer all or any part of
          its rights, benefits, obligations or liabilities (whether express or
          assumed) under this User Terms to any of its affiliates without
          requiring your further specific agreement. You may not at any time,
          without the prior written consent of THRILLER, transfer all or any
          part of your rights, benefits, obligations, or liabilities (whether
          express or assumed) under this User Terms.
        </p>
      </div>
      <div class="question">
        <h5>Severability</h5>
        <p>
          The Web Platform contains links or other connections to third party
          web Platforms. THRILLER provides these links only as a convenience to
          you and does not endorse, and is not responsible for, the contents on
          such linked Platforms. You accept any terms and conditions imposed by
          that third-party. Further, The THRILLER is not responsible for any
          viruses’ accesses through said third-party. If you decide to access
          linked third-party Web Platforms, you do so at your own risk.
        </p>
      </div>
      <div class="question">
        <h5>Links to third party</h5>
        <p>
          If any clause of this User Terms shall be deemed invalid, void, or for
          any reason unenforceable, such clause shall be deemed severable and
          shall not affect the validity and enforceability of the remaining
          clauses of this User Terms. This User Terms (as amended from time to
          time in accordance with the terms of this User Terms) sets forth the
          entire understanding and agreement between you and THRILLER with
          respect to the subject matter hereof. No person, who is not a party to
          this User Terms, shall have any right to enforce any term of this User
          Terms. <br />If this User Terms are translated into any language other
          than English, whether on the Platform or otherwise, the English text
          shall prevail.
        </p>
        <ul>
          <li>
            <h5>ELECTRONIC CONTRACTING</h5>
            <p>
              Your use of the Service includes the ability to enter into
              agreements and/or to make transactions electronically.
              <strong
                >YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE
                YOUR AGREEMENT AND INTENT TO BE BOUND BY AND TO PAY FOR SUCH
                AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE
                BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING
                TO ALL ELECTRONIC INFORMATION INCLUDES DATA AND INFORMATION IN
                THE FORM OF TEXT, CODES, SOUND, GRAPHICS, IMAMAGESCOMPTER
                PROGRAMS OR OTHERWISE AND TRANSACTIONS YOU ENTER INTO ON THIS
                SERVICE, INCLUDING NOTICES OF CANCELLATION, OTHER POLICIES,
                CONTRACTS, AND APPLICATIONS.</strong
              >&nbsp;DISCLAIMER: THRILLER shall not be liable for the loss of
              any saved/stored data in products that are either repaired or
              replaced.
            </p>
          </li>
        </ul>
      </div>
      <div class="question">
        <h5>Contact Us</h5>
        <p>
          Please contact us for any questions or comments (including all
          inquiries &amp; Complaints) regarding the Services:
          thefuture@thrillerme.com
        </p>
      </div>
    </div>
           `,
        },
        {
          id: "refund",
          title: "Refund Policy",
          content: `<p>WHAT IS YOUR POLICY FOR ONLINE PURCHASES?</p>
            <p>General Conditions Applicable to Returns:</p>
            <ul>
              <li>Subject to meeting the conditions set out in this Returns and Exchanges section, we offer a “no questions asked" free-returns policy which allows you to return delivered items to us for any reason up to 7 days after the delivery of your Order, free of charge.</li>
              <li>For clothing, returns must be unwashed and unworn. Products must be brought back in original packaging, with all labels attached.</li>
              <li>For accessories and figures, all sales are final.</li>
              <li>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging with a receipt or proof of purchase.</li>
              <li>Please note that authenticity tags or stickers must be intact. If removed, the item will not comply with our exchange policy and will not be accepted.</li>
              <li>If your return is not accepted you will be liable to pay for a shipping label to have the item sent back to you.</li>
              <li>For international orders, all orders are final. In an unlikely event if the shipment you have received is in a damaged condition or defective, please reach out to us at shipping@thrillerme.com</li>
              <li>Please do not hesitate to contact us should you require further clarification.</li>
            </ul>
           `,
        },
        {
          id: "shipping",
          title: "Shipping Policy",
          content: `<h5>Standard Delivery</h5>
          <p>Standard Delivery is FREE to the UAE and KSA. Standard Delivery times are between 4-7 working days.</p>
          <p>For standard purchases, you won't receive a shipping confirmation until the item has been verified and is en route to your delivery address. However, you can always check the status of your order prior to receiving it by viewing your pending orders from the Account Buying tab, which you can navigate to by clicking My Account > Buying.</p>
          <p>As a Buyer, you can expect to receive the following email notifications from Thriller for a standard purchase:</p>
            <ul>
              <li>An order confirmation (including order number) when your purchase is completed.</li>
              <li>A confirmation when the Seller has shipped the item to the Verification Centre.</li>
              <li>A notification when your item has been verified and shipped.</li>
              <li>A confirmation that your delivery has been received.</li>
            </ul>
          <h5>Express Delivery</h5>
          <p>Express Delivery is 35AED to the UAE. Express Delivery times are between 1-3 working days.</p>
          <p>You can identify Express Ship items by the rocket icon next to the product listing. Express Ship items are pre-verified and ready to be shipped. Once an order is placed, it gets shipped immediately. Express Ship orders are delivered to you within three business days maximum.</p>
          <h5>International Delivery</h5>
          <p>Please see the countries we ship to and their shipping rates below. All International Deliveries will not be subject to any additional payments/customs upon delivery. </p>
           `,
        },
        {
          id: "contact",
          title: "Contact Information",
          content: `
          <p>You can contact us 24/7 by submitting a support request, our Instagram page @thrillerme, or by sending us an email at the thefuture@thrillerme.com, or call us at 800779979.</p>
           `,
        },
      ],
      showNotFound: false,
      showError: false,
      freqLoading: false,
      promoLoading: false,
      promos: [],
      qID: 0,
      qShoe: null,
      qSize: "",
      qPrice: 0,
      processing: false,
      phoneError: false,
      saveInfo: false,
      //receiver: "ali.fullstack@gmail.com",
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "shipping@thrillerme.com",
      costData: [
        { country: "South Africa", cost: 116 },
        { country: "Australia", cost: 60 },
        { country: "Canada", cost: 67 },
        { country: "Austria", cost: 87 },
        { country: "Belgium", cost: 87 },
        { country: "Bulgaria", cost: 87 },
        { country: "Croatia", cost: 87 },
        { country: "Czechia", cost: 87 },
        { country: "Denmark", cost: 87 },
        { country: "Estonia", cost: 87 },
        { country: "Finland", cost: 87 },
        { country: "France", cost: 87 },
        { country: "Germany", cost: 87 },
        { country: "Greece", cost: 87 },
        { country: "Hungary", cost: 87 },
        { country: "Ireland", cost: 87 },
        { country: "Italy", cost: 87 },
        { country: "Latvia", cost: 87 },
        { country: "Lithuania", cost: 87 },
        { country: "Luxembourg", cost: 87 },
        { country: "Netherlands", cost: 87 },
        { country: "Poland", cost: 87 },
        { country: "Portugal", cost: 87 },
        { country: "Romania", cost: 87 },
        { country: "Slovakia", cost: 87 },
        { country: "Slovenia", cost: 87 },
        { country: "Spain", cost: 87 },
        { country: "Sweden", cost: 87 },
        { country: "Guam", cost: 143 },
        { country: "Hong Kong", cost: 65 },
        { country: "Palestinian Territories", cost: 220 },
        { country: "Japan", cost: 85 },
        { country: "Malaysia", cost: 70 },
        { country: "Malta", cost: 100 },
        { country: "Cyprus", cost: 100 },
        { country: "Mexico", cost: 65 },
        { country: "China", cost: 95 },
        { country: "Puerto Rico", cost: 75 },
        { country: "Singapore", cost: 30 },
        { country: "United Kingdom", cost: 65 },
        { country: "British Virgin Islands", cost: 165 },
        { country: "United Arab Emirates", cost: 35 },
        // { country: "others", cost: 145 },
      ],
      stripe: null,
      paymentRequest: null,
      elements: null,
      paymentRequestButton: null,
      errorMessage: null,
      tabbyStatus: "",
      tabbyError: false,
      referID: null,
    };
  },
  computed: {
    computedOptions() {
      if (this.myObj.country == "United Arab Emirates") {
        return this.options;
      } else return [this.options.at(-1)];
    },
    shipFee() {
      // if (this.selected == "standard") return 25;
      // else {
      //   let cc = this.costData.find((el) => el.country == this.myObj.country);
      //   if (cc) return cc.cost;
      //   else return 145;
      // }
      let cc = this.costData.find((el) => el.country == this.myObj.country);
      if (cc) return cc.cost;
      else return 145;
    },
    subTotal() {
      let addsOn = this.items.reduce((sum, el) => sum + el.Price, 0);
      return parseFloat((this.qPrice + addsOn).toFixed(2));
    },
    processFee() {
      return parseFloat((this.subTotal * 0.019).toFixed(2));
    },
    totalAmount() {
      let total = this.subTotal + this.processFee;
      if (
        this.selected == "standard" &&
        this.myObj.country == "United Arab Emirates"
      ) {
        return parseFloat((total - this.discount).toFixed(2));
      }
      return parseFloat((total + this.shipFee - this.discount).toFixed(2));
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.initializePixel();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    // console.log(this.$route);
    let query = this.$route.query;
    // console.log(query);
    if (!query.id || !query.size || !query.price) {
      this.showNotFound = true;
    } else if (query.error && ["cancel", "failure"].includes(query.error)) {
      this.showError = true;
    } else {
      this.LoadParams();
    }
  },
  methods: {
    initializePixel() {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      fbq("init", "3098857153686189", {
        em: "some@email.com", // optional, for advanced matching
      });
      fbq("track", "PageView");
    },
    trackPurchase(offerData) {
      if (!offerData.hasOwnProperty("value")) {
        console.warn(
          'Purchase event requires a "value" property. Adding a default value of ' +
            this.totalAmount
        );
        offerData.value = this.totalAmount;
      }

      // Ensure the value is a number
      offerData.value = Number(offerData.value);

      // Add currency if it's not present (Facebook requires it for Purchase events)
      if (!offerData.hasOwnProperty("currency")) {
        offerData.currency = "AED"; // Set a default currency, change as needed
      }

      fbq("track", "Purchase", offerData);
    },

    tryAgain() {
      this.showError = false;
      let query = this.$route.query;
      if (!query.id || !query.size || !query.price) {
        this.showNotFound = true;
      } else {
        this.LoadParams();
      }
    },
    initTabby() {
      if (this.paymentSelected == "tabby") {
        const tabby = new TabbyCard({
          selector: "#tabbyCard",
          currency: "AED",
          lang: "en",
          price: this.totalAmount,
          size: "wide", // narrow
          theme: "black",
          header: false,
        });
      }
    },
    countryChange() {
      // console.log(this.myObj.country);
      if (this.myObj.country != "United Arab Emirates") {
        this.selected = "standard";
      }
      this.updatePaymentRequest();
      this.initTabby();
      if (
        ["United Arab Emirates", "Saudi Arabia"].includes(this.myObj.country)
      ) {
        this.tabbyValidations();
      }
    },
    CheckEmail() {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let elem = this.$refs.email;
      return (elem.state = re.test(this.myObj.email));
    },
    CheckCountry() {
      let elem = this.$refs.country;
      if (this.myObj.country == "") {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    CheckFName() {
      let elem = this.$refs.fname;
      if (this.myObj.firstName == "") {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    CheckLName() {
      let elem = this.$refs.lname;
      if (this.myObj.lastName == "") {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    CheckAddress() {
      let elem = this.$refs.address;
      if (this.myObj.address == "") {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    CheckCity() {
      let elem = this.$refs.city;
      if (this.myObj.city == "") {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    CheckPostal() {
      if (this.myObj.country != "United Arab Emirates") {
        let elem = this.$refs.postal;
        if (this.myObj.postalCode == "") {
          return (elem.state = false);
        } else return (elem.state = true);
      } else return true;
    },
    CheckEmirate() {
      if (this.myObj.country == "United Arab Emirates") {
        let elem = this.$refs.emirate;
        if (this.myObj.emirate == "") {
          return (elem.state = false);
        } else return (elem.state = true);
      } else return true;
    },
    CheckPhone() {
      if (this.myObj.phone == "") {
        return (this.phoneError = true);
      } else return (this.phoneError = false);
    },
    formSubmit() {
      this.CheckEmail();
      this.CheckCountry();
      this.CheckFName();
      this.CheckLName();
      this.CheckAddress();
      this.CheckCity();
      this.CheckPostal();
      this.CheckEmirate();
      this.CheckPhone();

      if (
        this.myObj.country != "United Arab Emirates" &&
        this.selected == "express"
      ) {
        this.$bvToast.toast(
          "Express Delivery not available in " + this.myObj.country,
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          }
        );
      } else if (
        this.myObj.country == "United Arab Emirates" &&
        this.selected == "express" &&
        !this.qShoe.tag.toLowerCase().match("featured")
      ) {
        this.$bvToast.toast("Product is not available for express delivery.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      } else if (
        this.CheckEmail() == true &&
        this.CheckCountry() == true &&
        this.CheckFName() == true &&
        this.CheckLName() == true &&
        this.CheckAddress() == true &&
        this.CheckCity() == true &&
        this.CheckPostal() == true &&
        this.CheckEmirate() == true &&
        this.CheckPhone() == false
      ) {
        console.log(this.myObj);
        // this.sendMail();
        if (this.paymentSelected == "card") this.submitElement();
        else if (this.paymentSelected == "tabby") this.verifyTabby("redirect");
        else this.sendMail("show");
      } else {
        this.$bvToast.toast("Please provide all neccessary detials.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      }
    },
    submitElement() {
      this.processing = true;
      this.$refs.elementRef[0].submit();
      try {
        this.trackPurchase(this.myObj);
      } catch (error) {
        console.error("#PR", error);
      }
    },
    tokenCreated(token) {
      this.cardToken = token;
      // console.log(this.cardToken);
      this.processPayment();
    },
    handleError(e) {
      this.processing = false;
      console.log(e);
    },
    async createIntent() {
      try {
        const res = await fetch("https://api.thrillerme.com/stripe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: this.totalAmount * 100,
          }),
        });
        if (!res.ok) {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          return "";
        } else {
          const { clientSecret: clientSecret } = await res.json();
          return clientSecret;
        }
      } catch (error) {
        this.$bvToast.toast("Something went wrong!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
          solid: true,
        });
        return "";
      }
    },
    async processPayment() {
      this.processing = true;
      //
      const clientSecret = await this.createIntent();
      // console.log(clientSecret);
      if (clientSecret != "") {
        const result = await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: {
              token: this.cardToken.id,
            },
            billing_details: {
              name: this.myObj.firstName + " " + this.myObj.lastName,
              email: this.myObj.email,
              phone: this.myObj.phone,
              address: {
                line1: this.myObj.address,
                line2: this.myObj.apartment,
                city: this.myObj.city,
                state: this.myObj.city,
                postal_code:
                  this.myObj.country == "United Arab Emirates"
                    ? this.myObj.emirate
                    : this.myObj.postalCode,
                country: this.countries.find(
                  (el) => el.value == this.myObj.country
                ).code,
              },
            },
          },
        });

        this.processing = false;
        if (result.error) {
          this.$bvToast.toast(result.error.message, {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
        } else {
          // console.log("Payment confirmed:", result.paymentIntent);
          this.$bvToast.toast("Order Confirmed", {
            title: "Success!",
            variant: "success",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          this.sendMail();
        }
      } else {
        this.processing = false;
      }
    },
    sendMail(val) {
      try {
        this.trackPurchase(this.myObj);
      } catch (error) {
        console.error("#PR", error);
      }
      this.processing = true;

      let postVal =
        this.myObj.country == "United Arab Emirates"
          ? `Emirate: ${this.myObj.emirate}`
          : `Postal code: ${this.myObj.postalCode}`;

      let pLink = `www.thrillerme.com/${
        this.qShoe.name.toLowerCase().replace(/\s+/g, "-") + "_id_" + this.qID
      }`;

      let itemVal = `1) ${this.qShoe.name} (size: ${this.qSize})\t\t AED ${this.qPrice}\n`;
      this.items.forEach((el, ind) => {
        itemVal =
          itemVal + `      ${ind + 2}) ${el.Name}\t\t AED ${el.Price}\n`;
      });

      let text = `
      Customer Info
      ==============\n
      Email: ${this.myObj.email}
      First Name: ${this.myObj.firstName}
      Last Name: ${this.myObj.lastName}
      Country: ${this.myObj.country}
      Apartment: ${this.myObj.apartment}
      City: ${this.myObj.city}
      ${postVal}
      Phone: ${this.myObj.phone}\n
      Order Summary
      =============\n
      Delivery: ${this.selected} delivery
      Payment Method: ${
        val == "apple"
          ? "Apply Pay"
          : this.paymentOptions.find((el) => el.value == this.paymentSelected)
              .text
      }
      Gift Wrap: ${this.myObj.giftWrap}\n
      ${itemVal}
      Subtotal: AED ${this.subTotal}
      Shipping: ${
        this.selected == "standard" &&
        this.myObj.country == "United Arab Emirates"
          ? "Free"
          : `AED ${this.shipFee}`
      }
      Processing Fee: AED ${this.processFee}
      Discount: AED ${this.discount} - Discount Code: ${this.discountCode}
      Total: AED ${this.totalAmount}
      Product Link: ${pLink}
     `;

      // console.log(text);

      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.receiver,
          subject: "Thriller | Checkout",
          text: text,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            if (val == "show") {
              this.$bvToast.toast("Order Confirmed", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
                solid: true,
              });
            }
            if (this.paymentSelected == "card") {
              this.$refs.elementRef[0].clear();
            }
            this.myObj = {
              email: "",
              country: "United Arab Emirates",
              firstName: "",
              lastName: "",
              address: "",
              apartment: "",
              postalCode: "",
              city: "",
              phone: "",
              emirate: "",
              offer: false,
              giftWrap: "Eco-Friendly Packaging",
            };
            this.discount = 0;
            this.discountCode = "";
            this.items = [];
            // this.selected = "express";
            this.paymentSelected = "card";
            this.saveInfo = false;
          } else {
            if (val == "show") {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
                solid: true,
              });
            }
          }
        })
        .catch((err) => {
          // this.$bvToast.toast("Something went wrong!", {
          //   title: "Error!",
          //   variant: "danger",
          //   toaster: "b-toaster-top-center",
          //   solid: true,
          // });
          console.log(err);
        })
        .finally(() => (this.processing = false));
    },
    tabbyValidations() {
      if (this.paymentSelected == "tabby") {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let psCheck =
          this.myObj.country == "United Arab Emirates"
            ? this.myObj.emirate != ""
            : this.myObj.postalCode != "";

        if (
          // !this.tabbyStatus &&
          this.myObj.firstName != "" &&
          this.myObj.lastName != "" &&
          re.test(this.myObj.email) &&
          this.myObj.phone != "" &&
          this.myObj.city != "" &&
          this.myObj.address != "" &&
          psCheck
        ) {
          this.verifyTabby();
          console.log("verify");
        } else console.log("not valid");
      }
    },
    verifyTabby(text) {
      try {
        this.trackPurchase(this.myObj);
      } catch (error) {
        console.error("#PR", error);
      }

      this.processing = true;

      if (!this.referID) {
        this.referID = Math.floor(100000 + Math.random() * 900000);
      }

      let tItems = [
        {
          title: this.qShoe.name,
          description: this.qShoe.summary,
          quantity: 1,
          unit_price: `${this.qPrice}`,
          discount_amount: "0.00",
          reference_id: "-",
          image_url: this.qShoe.cover_image,
          product_url: `www.thrillerme.com/${
            this.qShoe.name.toLowerCase().replace(/\s+/g, "-") +
            "_id_" +
            this.qID
          }`,
          gender: "Male",
          category: "Shoes",
          color: "-",
          product_material: "-",
          size_type: "-",
          size: `${this.qSize}`,
          brand: "-",
        },
      ];

      this.items.forEach((el) => {
        tItems.push({
          title: el.Name,
          description: "",
          quantity: 1,
          unit_price: `${el.Price}`,
          discount_amount: "0.00",
          reference_id: "-",
          image_url: el.imgSrc,
          product_url: "-",
          gender: "Male",
          category: "Shoes",
          color: "-",
          product_material: "-",
          size_type: "-",
          size: "-",
          brand: "-",
        });
      });

      let tabObj = {
        payment: {
          amount: `${this.totalAmount}`,
          currency: "AED",
          installments: {
            count: 4,
          },
          description: "",
          buyer: {
            phone: this.myObj.phone,
            email: this.myObj.email,
            name: this.myObj.firstName + " " + this.myObj.lastName,
            dob: "1999-08-24",
          },
          shipping_address: {
            city: this.myObj.city,
            address: this.myObj.address,
            zip:
              this.myObj.country == "United Arab Emirates"
                ? this.myObj.emirate
                : this.myObj.postalCode,
          },
          order: {
            tax_amount: "0.00",
            shipping_amount:
              this.selected == "standard" &&
              this.myObj.country == "United Arab Emirates"
                ? "0.00"
                : `${this.shipFee}`,
            discount_amount: `${this.discount}`,
            reference_id: `${this.referID}`,
            items: tItems,
          },
          buyer_history: {
            registered_since: "2024-01-05T14:15:22Z",
            loyalty_level: 0,
          },
          order_history: [],
          meta: {
            order_id: null,
            customer: null,
          },
        },
        lang: "en",
        merchant_code: "TME",
        merchant_urls: {
          success: "https://www.thrillerme.com/",
          cancel: `https://checkout.thrillerme.com/?id=${this.$route.query.id}&size=${this.$route.query.size}&price=${this.$route.query.price}&error=cancel`,
          failure: `https://checkout.thrillerme.com/?id=${this.$route.query.id}&size=${this.$route.query.size}&price=${this.$route.query.price}&error=failure`,
        },
        token: null,
      };

      // console.log(tabObj);
      let endpoint = "tabby";
      if (this.myObj.country == "Saudi Arabia") endpoint = "tabbyKSA";

      let config = {
        method: "post",
        url: "https://api.thrillerme.com/stripe/" + endpoint,
        headers: {
          // Authorization: "Bearer pk_test_c47ef3b9-f03b-4253-9655-1195aa5d6d26",
          Authorization: "Bearer pk_3199d436-86ab-4d36-b0c1-43a632230a4c",
        },
        data: tabObj,
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
          this.tabbyStatus = res.data.status;
          if (this.tabbyStatus == "created") {
            this.tabbyError = false;
            if (text == "redirect") {
              this.saveTabby(res.data);
            }
          } else {
            this.tabbyError = true;
            if (text == "redirect") {
              this.$bvToast.toast(
                "Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.",
                {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                  solid: true,
                }
              );
            }
          }
        })
        .catch((err) => {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          console.log(err);
        })
        .finally(() => (this.processing = false));
    },
    saveTabby(val) {
      this.processing = true;

      let postVal =
        this.myObj.country == "United Arab Emirates"
          ? `Emirate: ${this.myObj.emirate}`
          : `Postal code: ${this.myObj.postalCode}`;

      let pLink = `www.thrillerme.com/${
        this.qShoe.name.toLowerCase().replace(/\s+/g, "-") + "_id_" + this.qID
      }`;

      let itemVal = `1) ${this.qShoe.name} (size: ${this.qSize})\t\t AED ${this.qPrice}\n`;
      this.items.forEach((el, ind) => {
        itemVal =
          itemVal + `      ${ind + 2}) ${el.Name}\t\t AED ${el.Price}\n`;
      });

      let emailText = `
      Customer Info
      ==============\n
      Email: ${this.myObj.email}
      First Name: ${this.myObj.firstName}
      Last Name: ${this.myObj.lastName}
      Country: ${this.myObj.country}
      Apartment: ${this.myObj.apartment}
      City: ${this.myObj.city}
      ${postVal}
      Phone: ${this.myObj.phone}\n
      Order Summary
      =============\n
      Delivery: ${this.selected} delivery
      Payment Method: Tabby
      Gift Wrap: ${this.myObj.giftWrap}\n
      ${itemVal}
      Subtotal: AED ${this.subTotal}
      Shipping: ${
        this.selected == "standard" &&
        this.myObj.country == "United Arab Emirates"
          ? "Free"
          : `AED ${this.shipFee}`
      }
      Processing Fee: AED ${this.processFee}
      Discount: AED ${this.discount} - Discount Code: ${this.discountCode}
      Total: AED ${this.totalAmount}
      Product Link: ${pLink}
     `;

      // console.log(text);
      let obj = {
        email: this.myObj.email,
        date: new Date().toJSON().split("T")[0],
        shoe_id: parseInt(this.qID),
        tabbyObj: JSON.stringify(val),
        tabbyOrderID: val.payment.id,
        referenceID: this.referID,
        emailText: emailText,
        shoe_price: this.qPrice,
      };
      console.log(obj);

      let config = {
        method: "post",
        url: "https://api.thrillerme.com/shoes/tabbyOrder",
        data: obj,
      };
      axios(config)
        .then((res) => {
          let url =
            val.configuration.available_products.installments[0].web_url;
          window.open(url, "_self");
        })
        .catch((err) => {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          console.log(err);
        })
        .finally(() => (this.processing = false));
    },
    handleResize() {
      if (window.innerWidth >= 992) this.visible = true;
    },
    setCollapse(index) {
      this.paymentOptions.forEach((el, ind) => {
        if (ind == index) el.state = true;
        else el.state = false;
      });
    },
    show(val) {
      let obj = this.infoData.find((el) => el.id == val);
      if (obj) {
        this.currentInfo = obj;
        this.$bvModal.show("modal-info");
      }
    },
    addItem(val) {
      this.items.push(val);
      this.updatePaymentRequest();
      this.initTabby();
    },
    removeItem(ind) {
      this.items.splice(ind, 1);
      this.updatePaymentRequest();
      this.initTabby();
    },
    applyDiscount(val) {
      let elem = this.$refs[`discount${val}`];
      if (this.discountCode) {
        let obj = this.promos.find(
          (el) => el.attributes.Code == this.discountCode
        );
        // console.log(obj);
        if (obj && obj.attributes.Active) {
          let dt = new Date(obj.attributes.ValidTill);
          dt.setHours(0, 0, 0, 0);
          let current = new Date();
          current.setHours(0, 0, 0, 0);
          // console.log(dt, current);

          if (dt > current) {
            this.discount = obj.attributes.Discount;
            elem.state = true;
          } else {
            this.discount = 0;
            elem.state = false;
          }
        } else {
          this.discount = 0;
          elem.state = false;
        }
      } else {
        this.discount = 0;
        elem.state = false;
      }
      this.updatePaymentRequest();
      this.initTabby();
    },
    LoadFreq() {
      this.freqLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/thriller-add-ons?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.freqItems = res.data.data;
          } else this.freqItems = [];
        })
        .catch((err) => console.log(err))
        .finally(() => (this.freqLoading = false));
    },
    LoadPromo() {
      this.promoLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/thriller-promo-codes?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.promos = res.data.data;
          } else this.promos = [];
        })
        .catch((err) => console.log(err))
        .finally(() => (this.promoLoading = false));
    },
    async LoadParams() {
      // this.qID = await this.Decrypt(this.$route.query.id);
      // this.qSize = await this.Decrypt(this.$route.query.size);
      // this.qPrice = await this.Decrypt(this.$route.query.price);
      const resp = await this.DecryptObj();

      // if (!this.qID || !this.qSize || !this.qPrice) {
      if (!resp) {
        this.showNotFound = true;
      } else {
        this.LoadItem();
        this.LoadFreq();
        this.LoadPromo();
        this.checkTabby();
        // this.qPrice = parseFloat(this.qPrice);

        // console.log("id", this.qID);
        // console.log("si", this.qSize);
        // console.log("pr", this.qPrice);

        this.initializeStripe();
      }
    },
    async initializeStripe() {
      // Initialize Stripe
      this.stripe = Stripe(this.publishableKey);

      this.initPaymentRequest();
    },
    async initPaymentRequest() {
      this.paymentRequest = this.stripe.paymentRequest({
        country: "AE",
        currency: "aed",
        total: {
          label: "Total",
          amount: this.totalAmount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestBillingAddress: true,
      });
      // console.log(this.paymentRequest);

      const result = await this.paymentRequest.canMakePayment();
      // console.log(result);

      if (result) {
        // Create and mount the Payment Request Button
        this.elements = this.stripe.elements();
        this.paymentRequestButton = this.elements.create(
          "paymentRequestButton",
          {
            paymentRequest: this.paymentRequest,
            style: {
              paymentRequestButton: {
                type: "default",
                theme: "dark",
                height: "64px",
              },
            },
          }
        );
        this.paymentRequestButton.mount("#payment-request-button");
        // console.log(this.paymentRequestButton);

        // Handle the payment request
        this.paymentRequest.on("paymentmethod", async (event) => {
          // console.log("ev", event);
          try {
            const clientSecret = await this.createIntent();

            if (clientSecret != "") {
              // Confirm the payment
              const { error } = await this.stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: event.paymentMethod.id,
                }
              );

              if (error) {
                this.errorMessage = error.message;
                event.complete("fail");
              } else {
                this.errorMessage = null;
                event.complete("success");
                this.$bvToast.toast("Order Confirmed", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                  solid: true,
                });
                this.sendMail("apple");
              }
            } else {
              this.errorMessage = "Payment failed. Please try again!";
              event.complete("fail");
            }
          } catch (error) {
            console.log("err", error);
            this.errorMessage = "Payment failed. Please try again.";
            event.complete("fail");
          }
        });
      }
    },
    updatePaymentRequest() {
      if (this.paymentRequest) {
        this.paymentRequest.update({
          total: {
            label: "Total",
            amount: this.totalAmount * 100,
          },
        });
      }
    },

    async Decrypt(val) {
      let config = {
        method: "get",
        url: "https://connect.myskool.app/api/tokens/decrypt?str=" + val,
      };
      try {
        let res = await axios(config);
        if (res.data.status == "success") {
          // console.log(res.data);
          return res.data.data;
        } else return "";
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    async DecryptObj() {
      let config = {
        method: "post",
        url: "https://api.thrillerme.com/banners/decryptObj",
        data: {
          shoeID: this.$route.query.id,
          size: this.$route.query.size,
          price: this.$route.query.price,
        },
      };
      try {
        let res = await axios(config);
        // console.log(res);
        if (res.data) {
          // console.log(res.data);
          this.qID = res.data.shoeID;
          this.qSize = res.data.size;
          this.qPrice = parseFloat(res.data.price);
          return true;
        } else return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    LoadItem() {
      let config = {
        method: "get",
        url: "https://api.thrillerme.com/shoes/" + this.qID,
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
          if (res.data) {
            this.qShoe = res.data;
            if (!this.qShoe.tag.toLowerCase().match("featured")) {
              this.options.splice(0, 1);
              this.selected = "standard";
              this.updatePaymentRequest();
            }
          } else {
            this.qShoe = null;
          }
          // console.log(this.qShoe);
        })
        .catch((err) => {
          this.qShoe = null;
          console.log(err);
        });
    },
    checkTabby() {
      let config = {
        method: "get",
        url: "https://api.thrillerme.com/stripe/showTabby",
      };
      axios(config)
        .then((res) => {
          if (res.data) {
            let obj = {
              text: "Pay in 4 with Tabby. No interest. No fees",
              value: "tabby",
              image: require("@/assets/tabby.svg"),
              state: false,
            };
            this.paymentOptions.splice(1, 0, obj);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
@font-face {
  font-family: NewIcon;
  font-display: swap;
  src: url(@/assets/ounass.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
body {
  color: #000;
}
#stripe-element-errors {
  color: var(--danger);
  margin-top: 10px;
}
.btn-dark {
  background-color: #000 !important;
}
.centered-width {
  max-width: 41rem !important;
}
@media (min-width: 992px) {
  .centered-width {
    max-width: 34rem !important;
  }
}
.bg-gray {
  background-color: #f5f5f5;
}
.cursor-pointer {
  cursor: pointer;
}
legend.adjusted-font {
  font-size: 1.35rem;
  font-weight: 600;
  color: #000;
}
.form-control {
  padding: 1.35rem 0.75rem !important;
  font-size: 14px !important;
}
.custom-select {
  height: 46px !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 10"><path d="M0 0L10 10L20 0" stroke="currentColor" stroke-width="3px" stroke-linejoin="round" fill="none" /></svg>') !important;
}
.form-control:focus,
.custom-select:focus {
  border: 2px solid !important;
  border-color: #000 !important;
  box-shadow: none !important;
}
.input-group .form-control:focus {
  border-right: none !important;
}
.input-group .form-control:focus ~ .input-group-append .input-group-text {
  border: 2px solid !important;
  border-color: #000 !important;
  border-left: none !important;
}
input[type="radio"] {
  width: 18px;
  height: 18px;
}
.custom-checkbox label {
  font-size: 14px;
}
.btn:focus {
  box-shadow: none;
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
.item-qty {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
}
.item-size {
  font-size: 12px;
}
.item-amount {
  font-size: 14px;
}
.freq-items {
  display: grid;
  grid-template-columns: minmax(0, 4.2rem) minmax(0, 1fr) minmax(
      auto,
      max-content
    );
  gap: 14px;
}
.font-600 {
  font-weight: 600;
}
.total-details {
  font-size: 14px;
}
.cross-out {
  /* text-decoration: 2px line-through;
  -webkit-text-decoration: 2px line-through; */
  position: relative;
  display: inline-block;
  color: black;
  text-decoration: none;
}
.cross-out::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: black;
  top: 50%;
  transform: translateY(-50%);
}
.policies {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 14px;
}
.policies li {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}
.small-label-input {
  padding-top: 0.95rem !important;
}
.small-label {
  font-size: 12px;
  line-height: 1;
  margin: 0;
  padding-left: 0.75rem;
  position: absolute;
  top: 5px;
}
.thumb-icon {
  font-size: 24px;
  font-family: NewIcon, sans-serif !important;
  font-style: normal !important;
  line-height: 1;
}
.thumb-icon:before {
  content: "\e01c";
}
</style>
