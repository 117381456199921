import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
  VBTooltip,
} from "bootstrap-vue";

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.directive("b-tooltip", VBTooltip);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
