<template>
  <!-- <div class="text-center border-bottom px-2 py-3" >
    <a href="https://thrillerme.com">
      <img src="../assets/logo.svg" style="max-width: 150px" />
    </a>
  </div> -->

  <div
    class="p-3 d-flex align-items-center justify-content-between border-bottom"
  >
    <div></div>
    <a href="https://thrillerme.com">
      <img src="../assets/logo.svg" style="max-width: 150px; width: 100%" />
    </a>
    <a href="https://wa.me/971589441895" target="_blank">
      <b-icon icon="telephone" variant="dark" font-scale="1.3"></b-icon>
    </a>
  </div>
</template>
<script>
export default {
  name: "Header",
};
</script>
<style></style>
